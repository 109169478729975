
import StoreLogs from "Pages/Moderation/StoreLog";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchStoreLogFilters } from "store/modules/moderation/storeLog/storeLogSlice";

export function StoreLogUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const purchaseId = searchParams.get("purchaseId");
    const userId = searchParams.get("userId");
    const dateFrom = searchParams.get("dateFrom");
    const dateTo = searchParams.get("dateTo");
    const status = searchParams.get("status");
    const paymentMethod = searchParams.get("paymentMethod");

    let propPaymentMethod = {
        'xsolla': 'Xsolla',
        'pagarme': 'Pagarme',
    }
    let propStatus = {
        'paid': 'Sim',
        'unpaid': 'Não',
    }
    

    const dispatch = useDispatch()

    if (purchaseId || userId || dateFrom || dateTo || status || paymentMethod) {
        fetchStoreLogFilters(dispatch, { purchaseId, userId, dateFrom, dateTo, status: status ? {name: propStatus[status], value: status} : '', paymentMethod: paymentMethod ? {name: propPaymentMethod[paymentMethod], value: paymentMethod} : ''})
        return <StoreLogs purchaseId={purchaseId ?? null} userId={userId ?? null} dateFrom={dateFrom ?? null} dateTo={dateTo ?? null} status={status ?? null} paymentMethod={paymentMethod ?? null}/>;
    }
    else{
        return <StoreLogs/>;
    }

}