import { createSlice } from '@reduxjs/toolkit'
import { getSelectedItemDetails } from 'services/itemService';

const selectedItemDetailsSlice = createSlice({
    name: 'selectedItemDetails',
    initialState: {
        details: {
            tableProperties: []
        }
    },
    reducers: {
        setSelectedItemDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
    },
})

export async function fetchSelectedItemDetails(dispatch, params){
    try{
        const details = await getSelectedItemDetails(params);
        dispatch(setSelectedItemDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}

export function cleanSelectedItemDetails(dispatch){
    dispatch(setSelectedItemDetailsDetails({
        details: {
            tableProperties: []
        }
    }));
}

export const { setSelectedItemDetailsDetails } = selectedItemDetailsSlice.actions
export default selectedItemDetailsSlice.reducer