import { createSlice } from '@reduxjs/toolkit'
import { getNews } from 'services/newsService';
import { getTournaments } from 'services/tournamentsService';

const selectedTournamentSlice = createSlice({
    name: 'selectedTournament',
    initialState: {},
    reducers: {
        setSelectedTournament: (state, action) => {
            let tournament;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                tournament = action.payload[0]
            } else {
                tournament = {}
            }            

            return tournament
        },
    },
})

export const selectTournamentForDetails = (params) => async (dispatch) => {
    try {
        const selectedTournament = await getTournaments(params, 0)
        dispatch(setSelectedTournament(selectedTournament))
    } catch (error) {
        console.error('Erro ao carregar torneio:', error)
    }
}

export const cleanTournamentForDetails = () => async (dispatch) => {
    dispatch(setSelectedTournament([]))
}

export const { setSelectedTournament } = selectedTournamentSlice.actions
export default selectedTournamentSlice.reducer
