import moment from "moment"

class Tournament {
    constructor({tournamentId, gameId, desc, details, bountyGold, bountyTokens, endTime, groupEntry, tdist_value}){
        this.tournamentId = tournamentId
        this.gameId = gameId
        this.desc = desc
        this.details = details
        this.bountyGold = bountyGold
        this.bountyTokens = bountyTokens
        this.endTime = endTime
        this.groupEntry = groupEntry
        this.tdist_value = tdist_value
    }

    get(){
        return{
            name: this.desc,
            details: this.details,
            tournamentId: this.tournamentId,
            gameId: this.gameId,
            bountyGold: this.bountyGold,
            bountyTokens: this.bountyTokens,
            groupEntry: this.groupEntry,
            firstPlace: this.tdist_value.split(':')[0],
            secondPlace: this.tdist_value.split(':')[1],
            thirdPlace: this.tdist_value.split(':')[2],
            endTime: moment(this.endTime).format('DD/MM/YYYY') + " " + moment(this.endTime).format('HH:mm'),
        }
    }

    /*
    totalWeigth(place){
        let firstPlace = Number(this.tdist_value.split(':')[0])
        let secondPlace = Number(this.tdist_value.split(':')[1])
        let thirdPlace = Number(this.tdist_value.split(':')[2])
        let sum = firstPlace + secondPlace + thirdPlace

        if(place == 'first'){
            return `${firstPlace} / ${sum}`
        }
        if(place == 'second'){
            return `${secondPlace} / ${sum}`
        }
        if(place == 'third'){
            return `${thirdPlace} / ${sum}`
        }
    }
     */
}

export default Tournament