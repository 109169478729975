import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedNewsCategoryDetails } from "store/modules/news/selectedNewsCategoryDetailSlice";
import { fetchSelectedNewsDetails } from "store/modules/news/selectedNewsDetailSlice";

export function UseNews(item){
    const news = useSelector((state) => {
        return (state.selectedNewsDetails.details) ? state.selectedNewsDetails.details : {tablePropertiesPt: [], tablePropertiesEn: [], tablePropertiesEs: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedNewsDetails(dispatch, { newsId: item.newsId });
    }, [item])

    return news
}

export function UseNewsCategory(item){
    const newsCategory = useSelector((state) => {
        return (state.selectedNewsCategoryDetails.details) ? state.selectedNewsCategoryDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedNewsCategoryDetails(dispatch, item.newsCategoryId);
    }, [item])

    return newsCategory
}