import { getModelItems } from "services/modelItemsService";

const { createSlice } = require("@reduxjs/toolkit");

const modelItemsSlice = createSlice({
    name:"modelItems",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            modelId: '',
            modelDesc: '',
            modelDetails: '',
            modelDescOption: '',
        }
    },
    reducers: {
        setModelItemsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addModelItemsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setModelItemsFilter: (state, action) => {
            return {...state, filters: action.payload}
        }
    },
})

export async function fetchModelItems(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const modelItems = await getModelItems(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addModelItemsData(modelItems))
        }
        else{
            dispatch(setModelItemsData(modelItems));
        }
        
        if(modelItems.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Items', error)
        dispatch(setCurrentSkip(0));
        dispatch(setModelItemsData([]));
        dispatch(setHasMore(false));
    }
}

export async function fetchModelItemsFilters(dispatch, params){
    dispatch(setModelItemsFilter(params));
}

export const { setModelItemsData, setCurrentSkip, setHasMore, addModelItemsData, setModelItemsFilter } = modelItemsSlice.actions
export default modelItemsSlice.reducer