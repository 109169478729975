import { categories } from "data/items"

const newsMapping = (item) => ({
    newsId: item.newsId ?? null,
    title: item.title ?? null,
    shortText: item.shortText ?? null,
    fullText: item.fullText ?? null,
    newsImageUrl: item.newsImageUrl ?? null,
    date: item.date ?? null,
    categories: item.categories ?? null,
    authorId: item.authorId ?? null,
    likes: item.likes ?? null,
    authorFirstName: item.authorFirstName ?? null,
    authorLastName: item.authorLastName ?? null,
    authorNameInstance: item.authorNameInstance ?? null,
    authorIcon: (!item.authorFirstName || !item.authorLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" :`https://cdn.minimania.app/avatars/${item.authorIcon}`,
})

const newsCategoriesMapping = (item) => ({
    newsCategoryId: item._id ?? null,
    name: item.name ?? null
})

export {newsMapping, newsCategoriesMapping}