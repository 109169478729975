import { createSlice } from '@reduxjs/toolkit'
import { getNews } from 'services/newsService';

const selectedNewsSlice = createSlice({
    name: 'selectedNews',
    initialState: {},
    reducers: {
        setSelectedNews: (state, action) => {
            let news;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                news = action.payload[0]
            } else {
                news = {}
            }            

            return news
        },
    },
})

export const selectNewsForDetails = (params) => async (dispatch) => {
    try {
        const selectedNews = await getNews(params, 0)
        dispatch(setSelectedNews(selectedNews))
    } catch (error) {
        console.error('Erro ao carregar Notícia:', error)
    }
}

export const cleanNewsForDetails = () => async (dispatch) => {
    dispatch(setSelectedNews([]))
}

export const { setSelectedNews } = selectedNewsSlice.actions
export default selectedNewsSlice.reducer
