import AvatarNoNames from "Pages/Moderation/AvatarNoNames";

import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchAvatarNoNamesFilters } from "store/modules/moderation/avatarNoNames/avatarNoNamesSlice";

export function AvatarNoNamesUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const nameValue = searchParams.get("nameValue");
    

    const dispatch = useDispatch()

    if (nameValue) {
        fetchAvatarNoNamesFilters(dispatch, { nameValue })
        return <AvatarNoNames nameValue={nameValue ?? null}/>;
    }
    else{
        return <AvatarNoNames/>;
    }

}