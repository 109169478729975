class AvatarNoName {
    constructor({name, id}){
        this.name = name
        this.id = id
    }

    get(){
        return({
            nameValue: this.name,
            id: this.id
        })
    }
}

export default AvatarNoName