class BlackListItem {
    constructor({address, id}){
        this.address = address
        this.id = id
    }

    get(){
        return({
            address: this.address,
            id: this.id
        })
    }
}

export default BlackListItem