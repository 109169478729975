import React, { useState, useEffect } from 'react'
import BasicTable from 'components/BasicTable'
import Modal from 'components/Modal'
import Button from 'components/form/Button'
import Select from 'components/form/Select'

import { FcSalesPerformance } from "react-icons/fc"
import { BiCoin, BiShield } from "react-icons/bi"
import { PiPokerChipBold } from "react-icons/pi"
import { BsStars } from "react-icons/bs";

// dados
import { ofences, users } from 'data/dashboard'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { fetchStaff, fetchInfractions } from 'store/modules'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import useUserPermission from 'domains/Permissions/useUserPermission'

const staffHeaders = [
    { title: 'Grupo', key: 'group' },
    { title: 'Usuário', key: 'user', copy: 'id' },
    { title: 'Avatar', key: 'avatar' },
    { title: 'Space', key: 'space' }
]

const infractionsHeaders = [
    { title: 'Horário', key: 'horario' },
    { title: 'Usuário', key: 'user', copy: 'userId' },
    { title: 'Ofensa', key: 'ofensa' },
    { title: 'Punição', key: 'punicao' },
    { title: 'Dada por', key: 'moderador' }
]

export default function ShiftLead() {
    const verifyOnline = useUserPermission(SMIPermissionsCode.MOD_SHIFTLEAD_ONLINE_STATUS);

    const [isLoading, setLoading] = useState(false)
    const [isLoadingInfractions, setLoadingInfractions] = useState(false)
    const dispatch = useDispatch()

    const getOnlineStaff = () => {
        setLoading(true)
        dispatch(fetchStaff())
    }

    const getInfractions = () => {
        setLoadingInfractions(true)
        dispatch(fetchInfractions())
    }

    // Redux
    const staff = useSelector((state) => {
        return Array.isArray(state.staff) ? state.staff : []
    })

    const infractions = useSelector((state) => {
        return Array.isArray(state.infractions) ? state.infractions : []
    })

    useEffect(() => {
        setLoading(false)
    }, [staff]);

    useEffect(() => {
        setLoadingInfractions(false)
    }, [infractions]);

    useEffect(() => {
        getOnlineStaff()
        getInfractions()
    }, [])

    return (
        <>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-5'>
                    {verifyOnline &&
                        <BasicTable
                            title='Equipe Online'
                            headers={staffHeaders}
                            items={staff}
                            isLoading={isLoading}
                            slotActions={<Button text="Atualizar" onClick={getOnlineStaff} color="cyan" />}
                        />
                    }

                </div>
                <div className='col-span-7'>
                    <BasicTable
                        title='Infrações Recentes'
                        headers={infractionsHeaders}
                        items={infractions}
                        isLoading={isLoadingInfractions}
                        slotActions={(
                            <>
                                {/* <Select items={ ofences } />
                                <Select items={ users } /> */}
                                <Button text="Atualizar" onClick={getInfractions} color="cyan" />
                            </>
                        )}
                    />
                </div>
            </div>

            <Modal isOpen={false} />
        </>
    )
}
