import { createSlice } from '@reduxjs/toolkit'
import { getSelectedModelItemDetails } from 'services/modelItemsService';

const selectedModelItemDetailsSlice = createSlice({
    name: 'selectedModelItemDetails',
    initialState: {
        details: {
            tableProperties: []
        }
    },
    reducers: {
        setSelectedModelItemDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
    },
})

export async function fetchSelectedModelItemDetails(dispatch, params){
    try{
        const details = await getSelectedModelItemDetails(params);
        dispatch(setSelectedModelItemDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}

export const { setSelectedModelItemDetailsDetails } = selectedModelItemDetailsSlice.actions
export default selectedModelItemDetailsSlice.reducer