import DefaultForm from "components/form/DefaultForm"
import { uuidMask } from "helpers/masks"
import { useDispatch } from "react-redux";
import { fetchNewsFilters } from "store/modules/news/newsSlice";

export default function NewsFilter({onFilterChange, defaultValues}){
    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        newValue[name] = value;

        fetchNewsFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'newsId',
            label: 'ID da Notícia',
            value: defaultValues.newsId,
            placeholder: 'ID da Notícia',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'title',
            label: 'Título',
            value: defaultValues.title,
            placeholder: 'Título',
            type: 'text',
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'authorId',
            label: 'ID do Autor',
            value: defaultValues.authorId,
            placeholder: 'ID do Autor',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'shortText',
            label: 'Texto Curto',
            value: defaultValues.shortText,
            placeholder: 'Texto Curto',
            type: 'text',
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'fullText',
            label: 'Texto Completo',
            value: defaultValues.fullText,
            placeholder: 'Texto Completo',
            type: 'text',
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'categoryId',
            label: 'ID da Categoria',
            value: defaultValues.categoryId,
            placeholder: 'ID da Categoria',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'dateFrom',
            label: 'De:',
            value: defaultValues.dateFrom,
            placeholder: '',
            type: 'datetime-local',
            gridClass: 'col-span-6'
        },
        {
            name: 'dateTo',
            label: 'Até:',
            value: defaultValues.dateTo,
            placeholder: '',
            type: 'datetime-local',
            gridClass: 'col-span-6'
        }, 
    ]

    return <DefaultForm fields={fields} onChange={handleSubmit}/>
}