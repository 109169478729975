import { createSlice } from '@reduxjs/toolkit'
import { getSelectedNewsDetails } from 'services/newsService';

const selectedNewsDetailsSlice = createSlice({
    name: 'selectedNewsDetails',
    initialState: {
        details: {
            tablePropertiesPt: [],
            tablePropertiesEn: [],
            tablePropertiesEs: []
        }
    },
    reducers: {
        setSelectedNewsDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
    },
})

export async function fetchSelectedNewsDetails(dispatch, params){
    try{
        const details = await getSelectedNewsDetails(params);
        console.log(details)
        dispatch(setSelectedNewsDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}

export const { setSelectedNewsDetailsDetails } = selectedNewsDetailsSlice.actions
export default selectedNewsDetailsSlice.reducer