import { uuidMask } from 'helpers/masks'
import DefaultForm from 'components/form/DefaultForm'
import { useDispatch } from 'react-redux';
import { fetchAvatarNoNamesFilters } from 'store/modules/moderation/avatarNoNames/avatarNoNamesSlice';

export default function AvatarNoNamesFilter ({onFilterChange, defaultValues}) {

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
            newValue[name] = value;

        fetchAvatarNoNamesFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'nameValue',
            label: 'Nome',
            value: defaultValues.nameValue,
            placeholder: 'Nome',
            type: 'text',
            validate: [],
            gridClass: 'col-span-12'
        }
    ]
    

    return (
        <>
            <DefaultForm fields={ fields } onChange={handleSubmit} />
        </>
    )
}
