import { getNewsCategoriesSkip } from "services/newsService";

const { createSlice } = require("@reduxjs/toolkit");

const newsCategoriesSlice = createSlice({
    name: 'newsCategories',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
    },
    reducers: {
        setNewsCategoriesData: (state, action) => {
            return {...state, data: action.payload}
        },
        addNewsCategoriesData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        }
    }
})

export async function fetchNewsCategories(dispatch, skip){
    const LIMIT = 200;
    try{
        const newsCategories = await getNewsCategoriesSkip(skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addNewsCategoriesData(newsCategories))
        }
        else{
            dispatch(setNewsCategoriesData(newsCategories));
        }
        
        if(newsCategories.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Categorias', error)
    }
}

export const { setNewsCategoriesData, addNewsCategoriesData, setCurrentSkip, setHasMore } = newsCategoriesSlice.actions
export default newsCategoriesSlice.reducer