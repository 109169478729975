import { fetchSelectedNewsCategoryDetails } from "store/modules/news/selectedNewsCategoryDetailSlice";
import { UseNewsCategory } from "./newsSearch";
import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable';
import style from '../../../styles/UserTabs.module.css'
import styles from '../../../styles/Search.module.css'
import Button from 'components/form/Button';
import Modal from 'components/Modal';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteNewsCategory, saveNewsCategory } from "services/newsService";
import { fetchNewsCategories } from "store/modules/news/newsCategoriesSlice";
import { cleanNewsCategoryForDetails } from "store/modules/news/selectedNewsCategorySlice";

export default function CategoryDetails({ item }) {

    const newsCategoriesHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const firstNewsCategoryData = UseNewsCategory(item);
    const [newsCategoryData, setNewsCategoryData] = useState(firstNewsCategoryData)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [handleValueChange, setHandleValueChange] = useState(true)

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    useEffect(() => {
        let firstNewsCategoryDataCopy = { ...firstNewsCategoryData }
        setNewsCategoryData(firstNewsCategoryDataCopy)

        setOriginalValues({
            newsCategoryId: firstNewsCategoryData.newsCategoryId,
            ptName: firstNewsCategoryData.ptName,
            enName: firstNewsCategoryData.enName,
            esName: firstNewsCategoryData.esName,

        })

        setChangedValues({
            newsCategoryId: firstNewsCategoryData.newsCategoryId,
            ptName: firstNewsCategoryData.ptName,
            enName: firstNewsCategoryData.enName,
            esName: firstNewsCategoryData.esName,

        })

    }, [firstNewsCategoryData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");
        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name != "ID Categoria") {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = () => {
        let newsCategoryDataCopy = { ...newsCategoryData, tableProperties: [...newsCategoryData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        if (inputValue != '') {
            for (let i = 0; i < newsCategoryData.tableProperties.length; i++) {
                if (newsCategoryData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    newsCategoryDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                    newsCategoryDataCopy[currentItem.key] = inputValue;
                    changedValuesCopy[currentItem.key] = inputValue;
                }
            }
            setChangedValues(changedValuesCopy)
            setNewsCategoryData(newsCategoryDataCopy);
        }

        setCurrentItem({});
        setInputValue("");
        handleCloseModal();
    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setNewsCategoryData(firstNewsCategoryData)
    }

    const dispatch = useDispatch()

    const handleSaveChanges = async () => {
        try {
            let originalValuesCopy = { ...originalValues }
            let changedValuesCopy = { ...changedValues }
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);

            const propertyApi = {
                ptName: 'name.pt-BR',
                enName: 'name.en-US',
                esName: 'name.es-ES'
            }

            let changes = {}

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if (originalValues[propName] !== changedValues[propName]) {
                    changes[propertyApi[propName]] = changedValues[propName]
                }
            }

            await saveNewsCategory(item.newsCategoryId, changes)

            setChangedValues(changedValuesCopy)
            setOriginalValues(changedValuesCopy)

            fetchSelectedNewsCategoryDetails(dispatch, item.newsCategoryId);
            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }
    }

    const [isDeleteNewsCategory, setIsDeleteNewsCategory] = useState(false)

    const handleDeleteNewsCategory = async () => {
        try {
            await deleteNewsCategory(item.newsCategoryId)

            await fetchNewsCategories(dispatch, 0);
            dispatch(cleanNewsCategoryForDetails())


            handleCloseDeleteNewsCategoryModal()
            toast.success('Categoria Removida com Sucesso!')
        }
        catch {
            toast.error('Erro ao Remover Categoria!')
        }
    }

    const handleOpenDeleteNewsCategoryModal = () => {
        setIsDeleteNewsCategory(true)
    }

    const handleCloseDeleteNewsCategoryModal = () => {
        setIsDeleteNewsCategory(false)
    }

    return (
        <>
            <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                <BasicTable
                    title={item.newsCategoryId}
                    slotActions={<Button text="Excluir Categoria" onClick={handleOpenDeleteNewsCategoryModal} color="cyan" />}
                    onSelectRow={handleSelectRow}
                    headers={newsCategoriesHeaders}
                    items={newsCategoryData.tableProperties}
                    slotHeader={(handleValueChange) ? "" :
                        <div className={style.header}>
                            <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                            <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                        </div>
                    }
                />
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
            >
                <div className={style.modalContent}>
                    <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>
                    <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                    <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                </div>
            </Modal>
            <Modal
                header={`Atenção!`}
                isOpen={isDeleteNewsCategory}
                onClose={handleCloseDeleteNewsCategoryModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>Deseja apagar permanentemente a categoria?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDeleteNewsCategory} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeleteNewsCategoryModal} color="cyan" />
                    </div>
                </div>
            </Modal>
        </>
    )
}