import { getEmailProviderBlacklist } from "services/blockFilterService";

const { createSlice } = require("@reduxjs/toolkit");

const emailProviderBlacklistSlice = createSlice({
    name: 'emailProviderBlacklist',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            provider: ''
        }
    },
    reducers: {
        setEmailProviderBlacklistData: (state, action) => {
            return {...state, data: action.payload}
        },
        addEmailProviderBlacklistData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setEmailProviderBlacklistFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchEmailProviderBlacklist(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const emailProviderBlacklist = await getEmailProviderBlacklist(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addEmailProviderBlacklistData(emailProviderBlacklist))
        }
        else{
            dispatch(setEmailProviderBlacklistData(emailProviderBlacklist));
        }
        
        if(emailProviderBlacklist.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter EmailProviderBlacklist', error)
    }
}

export async function fetchEmailProviderBlacklistFilters(dispatch, params){
    dispatch(setEmailProviderBlacklistFilter(params));
}

export const { setEmailProviderBlacklistData, addEmailProviderBlacklistData, setCurrentSkip, setHasMore, setEmailProviderBlacklistFilter } = emailProviderBlacklistSlice.actions
export default emailProviderBlacklistSlice.reducer