import handleRequestError from "helpers/apiErrorHandler";
import api, { apiNormalize } from "./api";
import { modelItemsMapping } from "domains/Item/ModelItem/mapping";
import ModelItem from "domains/Item/ModelItem/ModelItem";
import ModelItemDetail from "domains/Item/ModelItem/ModelItemDetail";

export async function getModelItems(params, skip) {
    try {
        const response = await api.get("/items/modelItems", { params: { ...params, skip } });
        const modelitems = apiNormalize.responseMapping(response.data, modelItemsMapping)
        const normalizedModelItems = modelitems.map(modelItem => new ModelItem(modelItem).get());
        return normalizedModelItems
    }
    catch (error) {
        handleRequestError(error)
    }
}

export async function getModelItem(params) {
    try {
        const response = await api.get("/items/modelItem", { params });
        const modelitems = apiNormalize.responseMapping(response.data, modelItemsMapping)
        const normalizedModelItems = modelitems.map(modelItem => new ModelItem(modelItem).get());
        return normalizedModelItems
    }
    catch (error) {
        handleRequestError(error)
    }
}

export async function getSelectedModelItemDetails(params){
    try {
        const response = await api.get("/items/modelItem", { params });
        const modelitems = apiNormalize.responseMapping(response.data, modelItemsMapping)
        const normalizedModelItems = modelitems.map(modelItem => new ModelItemDetail(modelItem).get());
        return normalizedModelItems[0]
    }
    catch (error) {
        handleRequestError(error)
    }
}

export async function saveModelItem(id, changes){
    try{
        const response = await api.post(`/items/modelItem/${id}/saveModelItem`, {changes})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export const saveModelItemIcon = async (id, img, name) => {
    try {
        const formData = new FormData();
        formData.append('img', img);
        formData.append('name', name);
        const response = await api.post(`/items/modelItem/${id}/saveModelItemIcon`, formData);
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const saveItemXml = async (modelId, blob) => {
    try {
        const formData = new FormData();
        formData.append('blob', blob);
       
        const response = await api.post(`/items/modelItem/${modelId}/saveItemXml`, formData);
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}