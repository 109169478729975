const abuseReportsMapping = (item) => ({
    date: item.date ?? null,
    type: item.type ?? null,
    reason: item.reason ?? null,
    category: item.category ?? null,
    status: item.status ?? null,
    reporterFirstName: item.reporterFirstName ?? null,
    reporterLastName: item.reporterLastName ?? null,
    reporterNameInstance: item.reporterNameInstance ?? null,
    reportedFirstName: item.reportedFirstName ?? null,
    reportedLastName: item.reportedLastName ?? null,
    reportedNameInstance: item.reportedNameInstance ?? null,
    assignedUserFirstName: item.assignedUserFirstName ?? null,
    assignedUserLastName: item.assignedUserLastName ?? null,
    reporterId: item.reporterId ?? null,
    reportedId: item.reportedId ?? null,
    assignedToId: item.assignedToId ?? null,
    reportedIcon: item.reportedIcon ?? null,
    reporterIcon: item.reporterIcon ?? null,
    abuseReportId: item._id ?? null,
    proofList: item.proofList ?? null,
    reportedModNotes: item.reportedModNotes ?? '',
    reportedUserId: item.reportedUserId ?? null

})


export { abuseReportsMapping }