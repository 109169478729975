import EmailProviderBlacklist from "Pages/Moderation/EmailProviderBlacklist";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchEmailProviderBlacklistFilters } from "store/modules/moderation/emailProviderBlacklist/emailProviderBlacklistSlice";

export function EmailProviderBlacklistUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const provider = searchParams.get("provider");
    

    const dispatch = useDispatch() 

    if (provider) {
        fetchEmailProviderBlacklistFilters(dispatch, { provider })
        return <EmailProviderBlacklist provider={provider ?? null}/>;
    }
    else{
        return <EmailProviderBlacklist/>;
    }

}