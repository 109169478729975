import { createSlice } from '@reduxjs/toolkit'
import { getSelectedTournamentDetails } from 'services/tournamentsService';

const selectedTournamentDetailsSlice = createSlice({
    name: 'selectedTournamentDetails',
    initialState: {
        details: {
            tableProperties: []
        }
    },
    reducers: {
        setSelectedTournamentDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
    },
})

export async function fetchSelectedTournamentDetails(dispatch, params){
    try{
        const details = await getSelectedTournamentDetails(params, 0);
        console.log(details)
        dispatch(setSelectedTournamentDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}

export const { setSelectedTournamentDetailsDetails } = selectedTournamentDetailsSlice.actions
export default selectedTournamentDetailsSlice.reducer