import { createSlice } from '@reduxjs/toolkit'
import { getSelectedNewsCategoryDetails } from 'services/newsService';

const selectedNewsCategoryDetailsSlice = createSlice({
    name: 'selectedNewsCategoryDetails',
    initialState: {
        details: {
            tableProperties: []
        }
    },
    reducers: {
        setSelectedNewsCategoryDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
    },
})

export async function fetchSelectedNewsCategoryDetails(dispatch, id){
    try{
        const details = await getSelectedNewsCategoryDetails(0, id);
        console.log(details)
        dispatch(setSelectedNewsCategoryDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}

export const { setSelectedNewsCategoryDetailsDetails } = selectedNewsCategoryDetailsSlice.actions
export default selectedNewsCategoryDetailsSlice.reducer