import handleRequestError from "helpers/apiErrorHandler"
import api, { apiNormalize } from "./api"
import { widgetsMapping } from "domains/Item/Widget/mapping";
import Widget from "domains/Item/Widget/Widget";


export const getWidgets = async (params, skip) => {
    try {
        const response = await api.get("/items/widgets", { params: { ...params, skip } });
        const widgets = apiNormalize.responseMapping(response.data, widgetsMapping)
        const normalizedWidgets = widgets.map(widget => new Widget(widget).get());
        return normalizedWidgets
    }
    catch (error) {
        console.log(error)
        handleRequestError(error)
    }
}

export const deleteWidget = async (params) => {
    try {
        const response = await api.post(`/items/widgets/deleteWidget`, { params })
        return response.data
    }
    catch (error) {
        console.log(error)
        handleRequestError(error)
    }
}
