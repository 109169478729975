import Widget from "Pages/Items/Widget";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchModelItemsFilters } from "store/modules/items/model/modelItemsSlice";

export function WidgetUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const widgetId = searchParams.get("widgetId");
    const itemId = searchParams.get("itemId");
    const modelId = searchParams.get("modelId");
    const creatorAvatarId = searchParams.get("creatorAvatarId");

    const dispatch = useDispatch()

    if (widgetId || itemId || modelId || creatorAvatarId) {
        fetchModelItemsFilters(dispatch, {widgetId, itemId, modelId, creatorAvatarId})
        return <Widget widgetId={widgetId ?? null} itemId={itemId ?? null} modelId={modelId ?? null} creatorAvatarId={creatorAvatarId ?? null}/>;
    }
    else{
        return <Widget/>;
    }

}