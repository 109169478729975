import { createSlice } from '@reduxjs/toolkit'
import { getModelItem } from 'services/modelItemsService';

const selectedModelItemSlice = createSlice({
    name: 'selectedModelItem',
    initialState: {},
    reducers: {
        setSelectedModelItem: (state, action) => {
            let mission;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                mission = action.payload[0]
            } else {
                mission = {}
            }            

            return mission
        },
    },
})

export const selectModelItemForDetails = (params) => async (dispatch) => {
    try {
        const selectedModelItem = await getModelItem(params)
        dispatch(setSelectedModelItem(selectedModelItem))
    } catch (error) {
        console.error('Erro ao carregar Modelo do Item:', error)
    }
}

export const { setSelectedModelItem } = selectedModelItemSlice.actions
export default selectedModelItemSlice.reducer