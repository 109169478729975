import BlackList from "Pages/Moderation/BlackList";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchBlackListFilters } from "store/modules/moderation/blacklist/blackListSlice";

export function BlackListUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const address = searchParams.get("address");
    

    const dispatch = useDispatch() 

    if (address) {
        fetchBlackListFilters(dispatch, { address })
        return <BlackList address={address ?? null}/>;
    }
    else{
        return <BlackList/>;
    }

}