import moment from "moment"

class TournamentDetail {
    constructor({tournamentId, gameId, desc, details, bountyGold, bountyTokens, endTime, groupEntry, tdist_value}){
        this.tournamentId = tournamentId
        this.gameId = gameId
        this.desc = desc
        this.details = details
        this.bountyGold = bountyGold
        this.bountyTokens = bountyTokens
        this.endTime = endTime
        this.groupEntry = groupEntry
        this.tdist_value = tdist_value
    }

    get() {
        return {
            tableProperties: [
                {
                    key: 'tournamentId',
                    name: "ID Torneio",
                    value: this.tournamentId,
                },
                {
                    key: 'desc',
                    name: "Nome do Torneio",
                    value: this.desc,
                },
                {
                    key: 'details',
                    name: "Detalhes",
                    value: this.details,
                },
                {
                    key: 'gameId',
                    name: "Id Jogo",
                    value: this.gameId,
                },
                {
                    key: 'bountyGold',
                    name: "Prêmio em Moedas",
                    value: this.bountyGold,
                },
                {
                    key: 'bountyTokens',
                    name: "Prêmio em Tokens",
                    value: this.bountyTokens,
                },
                {
                    key: 'groupEntry',
                    name: "Grupo",
                    value: this.groupEntry,
                },
                {
                    key: 'firstPlace',
                    name: "1º Prêmio",
                    value: this.tdist_value.split(':')[0],
                },
                {
                    key: 'secondPlace',
                    name: "2º Prêmio",
                    value: this.tdist_value.split(':')[1],
                },
                {
                    key: 'thirdPlace',
                    name: "3º Prêmio",
                    value: this.tdist_value.split(':')[2],
                },
                {
                    key: 'endTime',
                    name: "Data de Término",
                    value: moment(this.endTime).format('DD/MM/YYYY') + " " + moment(this.endTime).format('HH:mm'),
                },
            ],
            name: this.desc,
            details: this.details,
            tournamentId: this.tournamentId,
            gameId: this.gameId,
            bountyGold: this.bountyGold,
            bountyTokens: this.bountyTokens,
            groupEntry: this.groupEntry,
            firstPlace: this.tdist_value.split(':')[0],
            secondPlace: this.tdist_value.split(':')[1],
            thirdPlace: this.tdist_value.split(':')[2],
            endTime: moment(this.endTime).format('DD/MM/YYYY') + " " + moment(this.endTime).format('HH:mm'),

        }

    }
}

export default TournamentDetail