import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode";
import useUserPermission from "domains/Permissions/useUserPermission";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from '../../../styles/Search.module.css'
import style from '../../../styles/UserTabs.module.css'
import BasicTable from "components/BasicTable";
import Button from "components/form/Button";
import { toast } from "react-toastify";
import Modal from "components/Modal";
import { fetchEmailProviderBlacklist } from "store/modules/moderation/emailProviderBlacklist/emailProviderBlacklistSlice";
import { createEmailProviderBlacklist, deleteEmailProviderBlacklist } from "services/blockFilterService";
import FormSearch from "./Partials/FormSearch";

export default function EmailProviderBlacklist({ provider }) {
    const verifyBlackList = useUserPermission(SMIPermissionsCode.MOD_VIEW_IP_BLACKLIST);
    const verifyAddIpBlackList = useUserPermission(SMIPermissionsCode.MOD_ADD_IP_BLACKLIST_ENTRIES);
    const verifyRemoveIpBlackList = useUserPermission(SMIPermissionsCode.MOD_REMOVE_ALL_IP_BLACKLIST_ENTRIES);

    const [blackListIds, setBlackListIds] = useState([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [providerInput, setProviderInput] = useState('')

    const [isDelete, setIsDelete] = useState(false)

    const [blackListHeaders, setBlackListHeaders] = useState([
        { title: 'Provedor', key: 'provider', copy:'provider', appear: true },
    ])

    const [blackListMomentHeaders, setBlackListMomentHeaders] = useState([...blackListHeaders]);

    const handleAppearHeader = (item) => {
        setBlackListHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setBlackListMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }

    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const emailProviderBlacklist = useSelector((state) => {
        return Array.isArray(state.emailProviderBlacklist.data) ? state.emailProviderBlacklist.data : []
    })

    const skip = useSelector((state) => {
        return (state.emailProviderBlacklist.currentSkip) ? state.emailProviderBlacklist.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.emailProviderBlacklist.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.emailProviderBlacklist.filters) ? state.emailProviderBlacklist.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [emailProviderBlacklist]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (provider) {
            setLoading(true)
            fetchEmailProviderBlacklist(dispatch, { provider }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.provider) query.append('provider', filter.form.provider);
        window.history.pushState({}, '', `/moderation/emailProviderBlacklist?${query.toString()}`);
        console.log(filter)
        fetchEmailProviderBlacklist(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchEmailProviderBlacklist(dispatch, filter.form, skip + 200);
    })

    const handleSelect = (item) => {
        let tempArr = [...blackListIds];
        if (!tempArr.includes(item.id)) {
            tempArr.push(item.id);
        }
        else {
            let index = tempArr.indexOf(item.id);
            tempArr.splice(index, 1);

        }
        setBlackListIds(tempArr);
    }

    const handleSelectAll = () => {
        let tempArr = []
        emailProviderBlacklist.map((item) => {
            tempArr.push(item.id)
        })
        if (JSON.stringify(tempArr) === JSON.stringify(blackListIds)) {
            tempArr = []
        }
        setBlackListIds(tempArr)
    }

    const handleDelete = async () => {
        try {
            await deleteEmailProviderBlacklist(blackListIds)
            fetchEmailProviderBlacklist(dispatch, filter.form, 0);

            setBlackListIds([])
            handleCloseDeleteModal()
            
            toast.success('Proibição(ões) Excluídas(s) com Sucesso!')
        }
        catch (ex) {
            console.log(ex)
            toast.error('Erro ao Excluir Proibição(ões)!')
        }
    }

    const handleOpenCreateModal = async () => {
        setIsCreateModalOpen(true)
    }

    const handleCloseCreateModal = () => {
        setProviderInput('')
        setIsCreateModalOpen(false)
    }

    const handleAdd = async () => {
        try {
            await createEmailProviderBlacklist(providerInput)
            fetchEmailProviderBlacklist(dispatch, filter.form, 0);

            setBlackListIds([])
            handleCloseCreateModal()

            toast.success('Proibição Salva com Sucesso!')
        }
        catch (ex) {
            console.log(ex)
            toast.error('Erro ao Salvar Proibição!')
        }
    }

    const handleOpenDeleteModal = () => {
        setIsDelete(true)
    }

    const handleCloseDeleteModal = () => {
        setIsDelete(false)
    }

    return (
        <>
            {verifyBlackList && <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={blackListHeaders}
                        headers={blackListMomentHeaders}
                        slotHeader={
                            <>

                                <FormSearch onFilterChange={handleSetFilter} defaultValues={filters} />
                            </>
                        }
                        title='Buscar Provedores Proibidos'
                        isLoading={isLoading && emailProviderBlacklist.length === 0}
                        slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                        selectItems={emailProviderBlacklist.map((value) => {
                            return {
                                item: value,
                                selected: blackListIds.includes(value.id)
                            }
                        })}
                        isSelectable={true}
                        onSelectItem={handleSelect}
                        slotFooter={
                            <div className='flex flex-row justify-between w-full'>
                                <div className={styles.slotFooter}>
                                    <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                    {(emailProviderBlacklist.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : emailProviderBlacklist.length} resultados</span>}
                                </div>
                                <div className='flex flex-row gap-[10px]'>
                                    <Button disabled={false} text="Selecionar Todos" onClick={handleSelectAll} color="cyan" />
                                    {verifyAddIpBlackList && <Button disabled={false} text="Adicionar Proibição" onClick={handleOpenCreateModal} color="cyan" />}
                                    {verifyRemoveIpBlackList && <Button disabled={blackListIds.length == 0} text="Excluir Proibição" onClick={handleOpenDeleteModal} color="cyan" />}
                                </div>
                            </div>

                        }
                    />
                    <Modal
                        header={``}
                        isOpen={isCreateModalOpen}
                        onClose={handleCloseCreateModal}
                        footer={<div className={style.modalFooter}><Button disabled={providerInput.trim() == ''} text="Salvar" onClick={handleAdd} color="cyan" /></div>}
                    >
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Provedor para proibir`}</span>
                            <div className="flex gap-2 items-center ">
                                <input className={style.modalInput} type="text" placeholder={'Provedor'} value={providerInput} onChange={(e) => setProviderInput(e.target.value)} />
                            </div>
                        </div>
                    </Modal>
                    <Modal
                header={`Atenção!`}
                isOpen={isDelete}
                onClose={handleCloseDeleteModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>{"Deseja apagar permanentemente a(s) proibição(ões)?"}?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDelete} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeleteModal} color="cyan" />
                    </div>
                </div>
            </Modal>
                </div>
            </div>}
        </>
    )
}