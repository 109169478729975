import BasicTable from "components/BasicTable";
import Button from "components/form/Button";
import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode";
import useUserPermission from "domains/Permissions/useUserPermission";
import { useEffect, useState } from "react";
import { BiCoin } from "react-icons/bi";
import { PiPokerChipBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { fetchModelItems } from "store/modules/items/model/modelItemsSlice";
import ModelItemsFilter from "./Partials/ModelItemsFilter";
import styles from 'styles/Search.module.css'
import ModelItemDetails from "./Partials/ModelItemDetails";
import { selectModelItemForDetails } from "store/modules/items/model/selectedModelItem";

export default function ModelItems({ modelId, modelDesc, modelDetails }) {
    const verifyItem = useUserPermission(SMIPermissionsCode.ITEMS_SEARCH);
    const iconObject = { key: 'modelIcon' }

    const [modelItemsHeaders, setModelItemsHeaders] = useState([
        { title: 'ID', key: 'modelId', copy: 'modelId', detailable: true, appear: true },
        { title: 'Nome', key: 'modelName', icon: () => iconObject, appear: true },
        { title: 'Detalhes', key: 'modelDetails', appear: true },
        { title: 'Tags', key: 'modelTags', appear: true },
        { title: 'Preço em Moedas', key: 'goldPrice', icon: <BiCoin className='w-4 h-4 mr-1 text-yellow-500' />, appear: true },
        { title: 'Preço em Fichas', key: 'tokenPrice', icon: <PiPokerChipBold className='w-4 h-4 mr-1 text-rose-500' />, appear: true },
    ])

    const [modelItemsMomentHeaders, setModelItemsMomentHeaders] = useState([...modelItemsHeaders]);

    const handleAppearHeader = (item) => {
        setModelItemsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setModelItemsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }

    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const modelItems = useSelector((state) => {
        return Array.isArray(state.modelItems.data) ? state.modelItems.data : []
    })

    const skip = useSelector((state) => {
        return (state.modelItems.currentSkip) ? state.modelItems.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.modelItems.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.modelItems.filters) ? state.modelItems.filters : {}
    })

    const selectedModelItem = useSelector((state) => {
        return state.selectedModelItem
    })

    useEffect(() => {
        setLoading(false)
    }, [modelItems]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (modelId || modelDesc || modelDetails) {
            setLoading(true)
            fetchModelItems(dispatch, { modelId, modelDesc, modelDetails }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.modelId) query.append('modelId', filter.form.modelId);
        if (filter.form.modelDesc) query.append('modelDesc', filter.form.modelDesc);
        if (filter.form.modelDetails) query.append('modelDetails', filter.form.modelDetails);
        window.history.pushState({}, '', `/items/model?${query.toString()}`);
        console.log(filter)
        fetchModelItems(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleSetFilterForSelect = (label, value, details) => {
        
        const prevForm = filter.form
        const prevValid = filter.isValid

        setFilter({ form: {...prevForm, 'modelId': value, 'modelDetails': details, 'modelDesc': label, }, isValid: prevValid })
        
       /*
        const prevForm = filter.form
        const prevValid = filter.isValid
        setFilter({ form: {...prevForm, 'modelDesc': label, }, isValid: prevValid })
        */
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchModelItems(dispatch, filter.form, skip + 200);
    })


    const handleItemDetails = (item) => {
        dispatch(selectModelItemForDetails({ id: item.id }))
    }
    return (
        <>
            {verifyItem && <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-8'>
                    <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={modelItemsHeaders}
                        headers={modelItemsMomentHeaders}
                        slotHeader={
                            <>
                            
                                <ModelItemsFilter onFilterChange={handleSetFilter} defaultValues={filters} onFilterChangeSelect={handleSetFilterForSelect} />
                            </>
                        }
                        title='Buscar Itens'
                        onDetailRow={handleItemDetails}
                        isLoading={isLoading && modelItems.length === 0}
                        slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                        items={modelItems}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(modelItems.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : modelItems.length} resultados</span>}
                            </div>
                        }
                    />
                </div>
                <div className='col-span-4'>
                    {selectedModelItem.id && <ModelItemDetails item={selectedModelItem} />}
                </div>
            </div>}
        </>
    )
}