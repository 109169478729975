import { getBlackList } from "services/blockFilterService";

const { createSlice } = require("@reduxjs/toolkit");

const blackListSlice = createSlice({
    name: 'blackList',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            address: ''
        }
    },
    reducers: {
        setBlackListData: (state, action) => {
            return {...state, data: action.payload}
        },
        addBlackListData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setBlackListFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchBlackList(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const blackList = await getBlackList(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addBlackListData(blackList))
        }
        else{
            dispatch(setBlackListData(blackList));
        }
        
        if(blackList.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter BlackList', error)
    }
}

export async function fetchBlackListFilters(dispatch, params){
    dispatch(setBlackListFilter(params));
}

export const { setBlackListData, addBlackListData, setCurrentSkip, setHasMore, setBlackListFilter } = blackListSlice.actions
export default blackListSlice.reducer