import { getNewsCatList } from "services/newsService";

const { createSlice } = require("@reduxjs/toolkit");

const newsCatListSlice = createSlice({
    name: 'newsCatList',
    initialState: {
        data: [],
    },
    reducers: {
        setNewsCatListData: (state, action) => {
            return { ...state, data: action.payload }
        }
    }
})

export async function fetchNewsCatList(dispatch) {
    try {
        const newsCatList = await getNewsCatList();
        dispatch(setNewsCatListData(newsCatList))


    }
    catch (error) {
        console.error('Erro ao obter Lista de Categorias', error)
    }
}
export const { setNewsCatListData } = newsCatListSlice.actions
export default newsCatListSlice.reducer