import useUserPermission from "domains/Permissions/useUserPermission";
import { UseModelItem } from "./itemSearch";
import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BasicTable from "components/BasicTable";
import Button from "components/form/Button";
import Modal from "components/Modal";
import style from '../../../styles/UserTabs.module.css'
import Tabs from "components/Tabs";
import { toast } from "react-toastify";
import { saveItemXml, saveModelItem, saveModelItemIcon } from "services/modelItemsService";
import { fetchSelectedModelItemDetails } from "store/modules/items/model/selectedModelItemDetailSlice";


export default function ModelItemDetails({ item }) {
    const verifyItem = useUserPermission(SMIPermissionsCode.ITEMS_SEARCH);

    const modelItemsHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const firstModelItemData = UseModelItem(item);
    const [modelItemData, setModelItemData] = useState(firstModelItemData)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [handleValueChange, setHandleValueChange] = useState(true)

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    useEffect(() => {
        let firstModelItemDataCopy = { ...firstModelItemData }
        setModelItemData(firstModelItemDataCopy)

        setOriginalValues({
            id: firstModelItemData.id,
            modelId: firstModelItemData.modelId,
            modelName: firstModelItemData.modelName,
            modelDetails: firstModelItemData.modelDetails,
            modelTags: firstModelItemData.modelTags,
            goldPrice: firstModelItemData.goldPrice,
            tokenPrice: firstModelItemData.tokenPrice,
            modelIcon: firstModelItemData.modelIcon

        })

        setChangedValues({
            id: firstModelItemData.id,
            modelId: firstModelItemData.modelId,
            modelName: firstModelItemData.modelName,
            modelDetails: firstModelItemData.modelDetails,
            modelTags: firstModelItemData.modelTags,
            goldPrice: firstModelItemData.goldPrice,
            tokenPrice: firstModelItemData.tokenPrice,
            modelIcon: firstModelItemData.modelIcon

        })

    }, [firstModelItemData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");
        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name != "ID" && item.name != 'ID Modelo') {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = () => {
        let modelItemDataCopy = { ...modelItemData, tableProperties: [...modelItemData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        if (inputValue != '') {
            for (let i = 0; i < modelItemData.tableProperties.length; i++) {
                if (modelItemData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    modelItemDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                    modelItemDataCopy[currentItem.key] = inputValue;
                    changedValuesCopy[currentItem.key] = inputValue;
                }
            }
            setChangedValues(changedValuesCopy)
            setModelItemData(modelItemDataCopy);
        }
        setCurrentItem({});
        setInputValue("");
        handleCloseModal();

    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setModelItemData(firstModelItemData)
    }

    const dispatch = useDispatch()

    const handleFileSubmit = async (e) => {
        try {
            const file = e.target.files[0];

            fetch(item.modelXml)
                .then(response => response.text())
                .then(async (data) => {
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(data, "application/xml");

                    const spriteItem = xmlDoc.documentElement;
                    const icon = spriteItem.getAttribute("icon");
                    const parts = icon.split("/");
                    parts[parts.length - 1] = file.name;
                    const updatedPath = parts.join("/");
                    xmlDoc.documentElement.setAttribute("icon", `${updatedPath}`);


                    const serializer = new XMLSerializer();
                    const updatedXmlString = serializer.serializeToString(xmlDoc);

                    console.log(updatedXmlString)

                    const blob = new Blob([updatedXmlString], { type: 'application/xml' });

                    await saveItemXml(item.modelId, blob)

                })
                .catch(error => console.error("Erro ao carregar ou editar o XML:", error));



            await saveModelItemIcon(item.id, file, file.name);


            await fetchSelectedModelItemDetails(dispatch, { id: item.id });
            toast.success('Imagem Salva com Sucesso!');
        }
        catch {
            toast.error('Erro ao Salvar Imagem!');
        }
        handleCloseModal()


    }


    const handleSaveChanges = async () => {
        try {
            let originalValuesCopy = { ...originalValues }
            let changedValuesCopy = { ...changedValues }
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);

            const propertyApi = {
                modelName: 'model_desc',
                modelDetails: 'model_details',
                modelTags: 'model_tags',
                goldPrice: 'model_price_gold',
                tokenPrice: 'model_price_tokens',
            }

            let changes = {}

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if (originalValues[propName] !== changedValues[propName]) {
                    changes[propertyApi[propName]] = changedValues[propName]
                }
            }

            fetch(item.modelXml)
                .then(response => response.text())
                .then(async (data) => {
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(data, "application/xml");

                    if (changes['model_price_gold']) {
                        xmlDoc.documentElement.setAttribute("price", `${changes['model_price_gold']}`);
                    }
                    if (changes['model_price_tokens']) {
                        xmlDoc.documentElement.setAttribute("tokens", `${changes['model_price_tokens']}`);
                    }
                    if (changes['model_tags']) {
                        xmlDoc.documentElement.setAttribute("dropTargetList", `${changes['model_tags']}`);
                    }
                    if (changes['model_details']) {
                        xmlDoc.documentElement.setAttribute("desc", `${changes['model_details']}`);
                    }
                    if (changes['model_desc']) {
                        xmlDoc.documentElement.setAttribute("name", `${changes['model_desc']}`);
                    }

                    const serializer = new XMLSerializer();
                    const updatedXmlString = serializer.serializeToString(xmlDoc);

                    console.log(updatedXmlString)

                    const blob = new Blob([updatedXmlString], { type: 'application/xml' });

                    if (Object.keys(changes).length != 0) {
                        await saveItemXml(item.modelId, blob)
                    }

                })
                .catch(error => console.error("Erro ao carregar ou editar o XML:", error));

            await saveModelItem(item.id, changes)

            setChangedValues(changedValuesCopy)
            setOriginalValues(changedValuesCopy)

            fetchSelectedModelItemDetails(dispatch, { id: item.id });
            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }

    }


    const tabs = [
        {
            id: 1,
            tabTitle: 'Propriedades',
            content: (
                <>


                    <BasicTable
                        onSelectRow={handleSelectRow}
                        headers={modelItemsHeaders}
                        items={modelItemData.tableProperties}
                        slotHeader={(handleValueChange) ? "" :
                            <div className={style.header}>
                                <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                                <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                            </div>
                        }
                    />

                    {verifyItem && <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                            {(modalTitle != 'Ícone') ?

                                (modalTitle != 'Preço em Moedas' && modalTitle != 'Preço em Fichas') ?
                                    <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                    : <input className={style.modalInput} type="number" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />

                                :

                                <div className={style.modalImgContent}>
                                    <img className={style.modalImg} src={`${inputValue}`} />
                                    <span className={style.modalImgLabel}>{`Nova Imagem para o Modelo:`}</span>
                                    <input className={style.inputFile} type='file' accept=".png" onChange={handleFileSubmit} />

                                </div>

                            }

                            <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                        </div>
                    </Modal>}
                </>
            )
        }
    ]

    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-auto p-2'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <div className='p-2 text-sm' style={{ display: 'flex', margin: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <b>{item.modelName}</b>
                            <p>{item.id}</p>
                        </div>
                        {/* verifySpaceRemove && <Button text="Devolver Item" color="cyan" onClick={handleReturnToInventory} disabled={!(itemData.spaceId != '-')} />*/}
                    </div>
                )}
            />
        </div>

    )
}
