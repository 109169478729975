import { useEffect, useState } from "react";
import { UseItem } from "./itemSearch";
import BasicTable from "components/BasicTable";
import Button from "components/form/Button";
import { deleteItem, returnItemToInventory } from "services/itemService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { cleanSelectedItemDetails, fetchSelectedItemDetails } from "store/modules/items/selectedItemDetailSlice";
import Tabs from "components/Tabs";
import useUserPermission from "domains/Permissions/useUserPermission";
import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode";
import { fetchItems } from "store/modules/items/itemsSlice";
import { cleanItemForDetails } from "store/modules/items/selectedItemSlice";
import Modal from "components/Modal";

export default function Details({ filter, item, setSelected }) {
    const verifySpaceRemove = useUserPermission(SMIPermissionsCode.ITEMS_SPACE_REMOVE);

    const [isDeleteItem, setIsDeleteItem] = useState(false)
    const [isReturnItem, setIsReturnItem] = useState(false)

    const itemsHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value' },
    ]

    const firstItemData = UseItem(item);
    const [itemData, setItemData] = useState(firstItemData)

    useEffect(() => {
        let firstItemDataCopy = { ...firstItemData }
        setItemData(firstItemDataCopy)

    }, [firstItemData])

    const dispatch = useDispatch()

    const handleReturnToInventory = async () => {
        try {
            const tempArr = []
            tempArr.push(item.id)
            await returnItemToInventory(tempArr)

            fetchSelectedItemDetails(dispatch, { id: item.id });
            handleCloseReturnItemModal()
            toast.success('Item Retornado com Sucesso!')
        }
        catch {
            toast.error('Erro ao Retornar Item!')
        }
    }

    const handleDelete = async () => {
        try {
            const tempArr = []
            tempArr.push(item.id)

            await deleteItem(tempArr)
            fetchItems(dispatch, filter.form, 0);
            dispatch(cleanItemForDetails())
            setSelected([])
            handleCloseDeleteItemModal()

            toast.success('Item Deletado com Sucesso!')
        }
        catch {
            toast.error('Erro ao Deletar Item!')
        }
    }

    const tabs = [
        {
            id: 1,
            tabTitle: 'Propriedades',
            content: (
                <BasicTable
                    height="100%"
                    headers={itemsHeaders}
                    items={itemData.tableProperties}
                />
            )
        }
    ]

    const handleOpenDeleteItemModal = () => {
        setIsDeleteItem(true)
    }

    const handleCloseDeleteItemModal = () => {
        setIsDeleteItem(false)
    }

    const handleOpenReturnItemModal = () => {
        setIsReturnItem(true)
    }

    const handleCloseReturnItemModal = () => {
        setIsReturnItem(false)
    }

    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-auto p-2'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <div className='p-2 text-sm' style={{ display: 'flex', margin: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <b>{itemData.modelName}</b>
                            <p>{item.id}</p>
                        </div>
                        <div className="flex flex-row gap-[5px]">
                            {verifySpaceRemove && <Button text="Devolver Item" color="cyan" onClick={handleOpenReturnItemModal} disabled={!(itemData.spaceId != '-')} />}
                            {verifySpaceRemove && <Button text="Deletar Item" color="cyan" onClick={handleOpenDeleteItemModal} disabled={false}/>}
                        </div>

                    </div>
                )}
            />
            <Modal
                header={`Atenção!`}
                isOpen={isDeleteItem}
                onClose={handleCloseDeleteItemModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>{"Deseja apagar permanentemente o item"}?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDelete} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeleteItemModal} color="cyan" />
                    </div>
                </div>
            </Modal>
            <Modal
                header={`Atenção!`}
                isOpen={isReturnItem}
                onClose={handleCloseReturnItemModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>{"Deseja Devolver permanentemente o item"}?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleReturnToInventory} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseReturnItemModal} color="cyan" />
                    </div>
                </div>
            </Modal>
        </div>
    )
}