import { uuidMask } from 'helpers/masks'
import DefaultForm from 'components/form/DefaultForm'
import { useDispatch, useSelector } from 'react-redux';
import { fetchModelItemsFilters } from 'store/modules/items/model/modelItemsSlice';
import { fetchModelList } from 'store/modules/items/modelListSlice';
import { useEffect, useState } from 'react';
import Select from 'react-select'

export default function ModelItemsFilter({ onFilterChange, defaultValues, onFilterChangeSelect }) {

    const dispatch = useDispatch();
    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#155e75' : '#d1d5db',
            '&:hover': {
                borderColor: '#d1d5db'
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#6b7280'
        })
    };

    const handleSubmit = (formData, isValid, name, value) => {
        let newValue = {}
        newValue[name] = value;

        onFilterChange({ form: { 
            ...formData, 
            modelDesc: defaultValues.modelDescOption ? defaultValues.modelDescOption.label : '', 
            modelId: defaultValues.modelId ? defaultValues.modelId : '', 
            modelDetails: defaultValues.modelDetails ? defaultValues.modelDetails : '', 
        }, isValid: isValid })

        fetchModelItemsFilters(dispatch, { ...defaultValues, ...newValue })

    }

    const handleEditEntryInput = (option) => {
        let label = option != null ? option.label : ''
        let value = option != null ? option.value : ''
        let details = option != null ? option.details : ''

        onFilterChangeSelect(label, value, details)
        let newValue = {};
        newValue['modelDesc'] = label;
        newValue['modelDescOption'] = option;

        newValue['modelId'] = value;
        newValue['modelDetails'] = details
        
        fetchModelItemsFilters(dispatch, { ...defaultValues, ...newValue })
    }

    const modelList = useSelector((state) => {
        return Array.isArray(state.modelList.data) ? state.modelList.data : []
    })

    useEffect(() => {
        if (modelList.length == 0) {
            fetchModelList(dispatch)
        }
    }, [])

    const fields = [
        {
            name: 'modelId',
            label: 'ID do Modelo',
            value: defaultValues.modelId,
            placeholder: 'ID do Modelo',
            type: 'text',
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'modelDetails',
            label: 'Detalhes do Modelo',
            value: defaultValues.modelDetails,
            placeholder: 'Detalhes do Modelo',
            type: 'text',
            validate: [],
            gridClass: 'col-span-6'
        },
    ]

    return (
        <>
            <div className='py-2 px-3'>
                <span class="block text-sm font-medium leading-6 text-gray-900 text-truncate overflow-hidden">Nome do Modelo</span>
                <Select
                    classNamePrefix="select"
                    onChange={handleEditEntryInput}
                    value={defaultValues.modelDescOption}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="categories"
                    options={modelList}
                    styles={customStyles}
                    placeholder={"Nome do Modelo"}
                />
            </div>
            <DefaultForm fields={fields} onChange={handleSubmit} />
        </>
    )
}
