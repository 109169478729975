import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import ItemsFilter from './Partials/ItemsFilter'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/form/Button'
import styles from 'styles/Search.module.css'
import style from 'styles/UserTabs.module.css'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import { toast } from 'react-toastify'
import { fetchWidgets } from 'store/modules/items/widget/widgetSlice'
import { createWidget, deleteWidget } from 'services/widgetService'
import Modal from 'components/Modal'
import WidgetsFilter from './Partials/WidgetsFilter'

export default function Widget({ widgetId, itemId, modelId, creatorAvatarId }) {
    const verifyItem = useUserPermission(SMIPermissionsCode.ITEMS_SEARCH);
    const iconObject = { key: 'modelIcon' }
    const avatarIconObject = { key: 'avatarIcon' }
    const [widgetIds, setWidgetIds] = useState([]);

    const [isImgModalOpen, setIsImgModalOpen] = useState(false)
    const [currentWidgetImg, setCurrentWidgetImg] = useState(true)
    const [isDelete, setIsDelete] = useState(false)

    const [widgetsHeaders, setWidgetsHeaders] = useState([
        { title: 'ID do Widget', key: 'smallWidgetId', copy: 'widgetId', detailable: true, appear: true },
        { title: 'ID do Item', key: 'smallItemId', copy: 'itemId', appear: true },
        { title: 'Nome do Modelo', key: 'modelName', icon: () => iconObject, copy: 'modelId', appear: true },
        { title: 'Nome do Criador', key: 'creatorAvatarName', icon: () => avatarIconObject, copy: 'avatarId', appear: true },
        { title: 'Loves', key: 'loves', appear: true },
        { title: 'Publicado', key: 'published', appear: true },
    ])

    const [widgetsMomentHeaders, setWidgetsMomentHeaders] = useState([...widgetsHeaders]);

    const handleAppearHeader = (item) => {
        setWidgetsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setWidgetsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }



    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const widgets = useSelector((state) => {
        return Array.isArray(state.widgets.data) ? state.widgets.data : []
    })

    const skip = useSelector((state) => {
        return (state.widgets.currentSkip) ? state.widgets.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.widgets.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.widgets.filters) ? state.widgets.filters : {}
    })

    const selectedWidget = useSelector((state) => {
        return state.selectedWidget
    })

    useEffect(() => {
        setLoading(false)
    }, [widgets]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (widgetId || itemId || modelId || creatorAvatarId) {
            setLoading(true)
            fetchWidgets(dispatch, { widgetId, itemId, modelId, creatorAvatarId }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.widgetId) query.append('widgetId', filter.form.widgetId);
        if (filter.form.itemId) query.append('itemId', filter.form.itemId);
        if (filter.form.modelId) query.append('modelId', filter.form.modelId);
        if (filter.form.creatorAvatarId) query.append('creatorAvatarId', filter.form.creatorAvatarId);
        window.history.pushState({}, '', `/items/widget?${query.toString()}`);
        fetchWidgets(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchWidgets(dispatch, filter.form, skip + 200);
    })

    const handleWidgetDetail = (item) => {
        setCurrentWidgetImg(item.imgFileName)
        handleOpenImgModal()
    }

    const handleOpenImgModal = () => {
        setIsImgModalOpen(true)
    }

    const handleCloseImgModal = () => {
        setCurrentWidgetImg('')
        setIsImgModalOpen(false)
    }

    const handleSelect = (item) => {
        let tempArr = [...widgetIds];
        if (!tempArr.includes(item.widgetId)) {
            tempArr.push(item.widgetId);
        }
        else {
            let index = tempArr.indexOf(item.widgetId);
            tempArr.splice(index, 1);

        }
        setWidgetIds(tempArr);
    }

    const handleSelectAll = () => {
        let tempArr = []
        widgets.map((item) => {
            tempArr.push(item.widgetId)
        })
        if (JSON.stringify(tempArr) === JSON.stringify(widgetIds)) {
            tempArr = []
        }
        setWidgetIds(tempArr)
    }

    const handleDeleteAll = async () => {
        try {
            await deleteWidget(widgetIds)
            fetchWidgets(dispatch, filter.form, 0);

            setWidgetIds([])
            handleCloseDeleteModal()

            toast.success('Widget(s) Deletado(s) com Sucesso!')
        }
        catch (ex) {
            console.log(ex)
            toast.error('Erro ao Deletar Widget(s)!')
        }
    }

    const handleOpenDeleteModal = () => {
        setIsDelete(true)
    }

    const handleCloseDeleteModal = () => {
        setIsDelete(false)
    }

    return (
        <>
            {verifyItem && <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <BasicTable
                        selectItems={widgets.map((value) => {
                            return {
                                item: value,
                                selected: widgetIds.includes(value.widgetId)
                            }
                        })}
                        isSelectable={true}
                        onSelectItem={handleSelect}
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={widgetsHeaders}
                        headers={widgetsMomentHeaders}
                        slotHeader={<WidgetsFilter onFilterChange={handleSetFilter} defaultValues={filters} />}
                        title='Buscar Widgets'
                        onDetailRow={handleWidgetDetail}
                        isLoading={isLoading && widgets.length === 0}
                        slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                        slotFooter={
                            <div className='flex flex-row justify-between w-full'>
                                <div className={styles.slotFooter}>
                                    <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                    {(widgets.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : widgets.length} resultados</span>}
                                </div>
                                <div className='flex flex-row gap-[10px]'>
                                    <Button disabled={false} text="Selecionar Todos" onClick={handleSelectAll} color="cyan" />
                                    <Button disabled={widgetIds.length == 0} text="Excluir Widgets" onClick={handleOpenDeleteModal} color="cyan" />
                                </div>
                            </div>

                        }
                    />
                </div>
                <Modal
                    header={``}
                    isOpen={isImgModalOpen}
                    onClose={handleCloseImgModal}
                    footer={``}
                >
                    <div className={style.modalContent}>
                        <img src={currentWidgetImg} style={{ width: '25vw', height: 'auto' }} />
                    </div>
                </Modal>
                <Modal
                header={`Atenção!`}
                isOpen={isDelete}
                onClose={handleCloseDeleteModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>{"Deseja apagar permanentemente o(s) widget(s)?"}?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDeleteAll} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeleteModal} color="cyan" />
                    </div>
                </div>
            </Modal>
            </div>}
        </>
    )
}