class ModelItemDetail {
    constructor({ id, modelId, modelName, modelDetails, modelTags, goldPrice, tokenPrice, modelIcon }) {
        this.id = id
        this.modelId = modelId
        this.modelName = modelName
        this.modelDetails = modelDetails
        this.modelTags = modelTags
        this.goldPrice = goldPrice
        this.tokenPrice = tokenPrice
        this.modelIcon = modelIcon
    }

    get() {
        return {
            tableProperties: [
                {
                    key: 'id',
                    name: "ID",
                    value: this.id,
                },
                {
                    key: 'modelId',
                    name: "ID Modelo",
                    value: this.modelId,
                },
                {
                    key: 'modelName',
                    name: "Nome",
                    value: this.modelName,
                },
                {
                    key: 'modelDetails',
                    name: "Detalhes",
                    value: this.modelDetails,
                },
                {
                    key: 'modelTags',
                    name: "Tags",
                    value: this.modelTags,
                },
                {
                    key: 'goldPrice',
                    name: "Preço em Moedas",
                    value: this.goldPrice,
                },
                {
                    key: 'tokenPrice',
                    name: "Preço em Fichas",
                    value: this.tokenPrice,
                },
                {
                    key: 'modelIcon',
                    name: "Ícone",
                    value: this.modelIcon,
                },
                
            ],
            id: this.id,
            modelId: this.modelId,
            modelName: this.modelName,
            modelDetails: this.modelDetails,
            modelTags: this.modelTags,
            goldPrice: this.goldPrice,
            tokenPrice: this.tokenPrice,
            modelIcon: this.modelIcon
        }
    }
}

export default ModelItemDetail