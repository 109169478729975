import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedTournamentDetails } from "store/modules/tournaments/selectedTournamentDetailSlice";

export function UseTournament(item){
    const tournament = useSelector((state) => {
        return (state.selectedTournamentDetails.details) ? state.selectedTournamentDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedTournamentDetails(dispatch, { tournamentId: item.tournamentId });
    }, [item])

    return tournament
}