import { uuidMask } from 'helpers/masks'
import DefaultForm from 'components/form/DefaultForm'
import { useDispatch } from 'react-redux';
import { fetchWidgetsFilters } from 'store/modules/items/widget/widgetSlice';

export default function WidgetsFilter({ onFilterChange, defaultValues }) {

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({ form: formData, isValid: isValid })

        let newValue = {};
        newValue[name] = value;

        fetchWidgetsFilters(dispatch, { ...defaultValues, ...newValue })

        console.log(defaultValues)
    }

    const fields = [
        {
            name: 'widgetId',
            label: 'ID do Widget',
            value: defaultValues.widgetId,
            placeholder: 'ID do Widget',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-3'
        },
        {
            name: 'idItem',
            label: 'ID do Item',
            value: defaultValues.idItem,
            placeholder: 'ID do Item',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-3'
        },
        {
            name: 'modelId',
            label: 'ID do Modelo',
            value: defaultValues.modelId,
            placeholder: 'ID do Modelo',
            type: 'text',
            validate: [],
            gridClass: 'col-span-3'
        },
        {
            name: 'creatorAvatarId',
            label: 'ID do Avatar Criador',
            value: defaultValues.creatorAvatarId,
            placeholder: 'ID do Avatar Criador',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-3'
        },
    ]


    return (
        <>
            <DefaultForm fields={fields} onChange={handleSubmit} />
        </>
    )
}
