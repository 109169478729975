const storeLogsMapping = (item) => ({
    purchaseId: item._id ?? null,
    userId: item.userId ?? null,
    userFirstName: item.userFirstName ?? null,
    userLastName: item.userLastName ?? null,
    paymentMethod: item.paymentMethod ?? null,
    status: item.status ?? null,
    date: item.timestamp ?? null,
    content: item.content ?? null

})


export { storeLogsMapping }