import { Paths } from "paths/Paths"
import { BiCoin } from "react-icons/bi"

class ModelListItem{
    constructor({modelId, modelName, modelIcon, modelDetails}){
        this.modelId = modelId
        this.modelName = modelName
        this.modelIcon = modelIcon
        this.modelDetails = modelDetails
    }

    get(){
        return{
            value: this.modelId,
            label: this.modelName,
            icon: this.modelIcon,
            details: this.modelDetails
        }
    }
}

export default ModelListItem