import News from "Pages/News";
import Tournaments from "Pages/Tournaments";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchTournamentsFilters } from "store/modules/tournaments/tournamentsSlice";

export function TournamentsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const tournamentId = searchParams.get("tournamentId");
    const gameId = searchParams.get("gameId");

    const dispatch = useDispatch()

    if (tournamentId || gameId) {
        fetchTournamentsFilters(dispatch, {tournamentId, gameId })
        return <Tournaments tournamentId={tournamentId ?? null} gameId={gameId ?? null}/>;
    }
    else{
        return <Tournaments/>;
    }

}