import React, { useRef } from 'react'
import DefaultForm from 'components/form/DefaultForm'
import { required, minLength, isEmail } from 'helpers/validation'
import { useDispatch } from 'react-redux'
import { fetchTransactionsFilters } from 'store/modules/transactions/transactionActions'

const TransactionsForm = ({ onFormChange, defaultValues }) => {
    const formRef = useRef()

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        
        let newValue= {};
        

        if(name == 'transactionType'){
            let propName = {
                'catalog': 'Catalog',
                'gift': 'Gift',
                'gold': 'Gold',
                'item.instance': 'Item Instance',
                'marketplace': 'Marketplace',
                'mission': 'Mission',
                'refund': 'Refund',
                'seed': 'Seed',
                'space': 'Space',
                'spintowin': 'Spin to Win',
                'trade': 'Trade',
            }
            newValue[name] = {name: propName[value], value};
        }
        else{
            newValue[name] = value;
        }

        onFormChange({ form: { 
            ...formData, 
            sender: defaultValues.sender ? defaultValues.sender : '', 
            receiver: defaultValues.receiver ? defaultValues.receiver : '', 
            desc: defaultValues.desc ? defaultValues.desc : '', 
            space_id: defaultValues.space_id ? defaultValues.space_id : '', 
            space_instance_id: defaultValues.space_instance_id ? defaultValues.space_instance_id : '', 
            item_id: defaultValues.item_id ? defaultValues.item_id : '', 
            dateFrom: defaultValues.dateFrom ? defaultValues.dateFrom : '', 
            dateTo: defaultValues.dateTo ? defaultValues.dateTo : '', 
            transactionType: defaultValues.transactionType ? defaultValues.transactionType.value : '', 
        }, isValid: isValid })

        fetchTransactionsFilters(dispatch, {...defaultValues, ...newValue})
    }

    // TODO: Tipo de transação deve ser dinâmico e buscar as opções a partir da API
    const transactionTypes = [
        { value: '', name: 'Todas'},
        { value: 'catalog', name: 'Catalog' },
        { value: 'gift', name: 'Gift' },
        { value: 'gold', name: 'Gold' },
        { value: 'item.instance', name: 'Item Instance' },
        { value: 'marketplace', name: 'Marketplace' },
        { value: 'mission', name: 'Mission' },
        { value: 'refund', name: 'Refund' },
        { value: 'seed', name: 'Seed' },
        { value: 'space', name: 'Space' },
        { value: 'spintowin', name: 'Spin to Win' },
        { value: 'trade', name: 'Trade' },
    ]

    const fields = [
        {
            label: 'Remetente',
            name: 'sender',
            value: defaultValues.sender,
            placeholder: 'Remetente',
            type: 'text',
            validate: [
                // {
                //     rule: required
                // }
            ],
            gridClass: 'col-span-4'
        },
        {
            label: 'Destinatário',
            name: 'receiver',
            // label: 'ID de Usuário',
            value: defaultValues.receiver,
            placeholder: 'Destinatário',
            type: 'text',
            validate: [
                // {
                //     rule: required
                // }
            ],
            gridClass: 'col-span-4'
        },
        {
            label: 'Descrição',
            name: 'desc',
            value: defaultValues.desc,
            placeholder: 'Descrição',
            type: 'text',
            gridClass: 'col-span-4'
        },
        {
            label: 'ID do Espaço',
            name: 'space_id',
            value: defaultValues.space_id,
            placeholder: 'ID do Espaço',
            type: 'text',
            gridClass: 'col-span-4'
        },
        {
            label: 'ID de Instância',
            name: 'space_instance_id',
            value: defaultValues.space_instance_id,
            placeholder: 'ID de Instância',
            type: 'text',
            gridClass: 'col-span-4'
        },
        {
            label: 'ID de Item',
            name: 'item_id',
            value: defaultValues.item_id,
            placeholder: 'ID de Item',
            type: 'text',
            gridClass: 'col-span-4'
        },
        {
            name: 'dateFrom',
            label: 'De',
            value: defaultValues.dateFrom,
            placeholder: '',
            type: 'date',
            gridClass: 'col-span-4'
        },
        {
            name: 'dateTo',
            label: 'Até',
            value: defaultValues.dateTo,
            placeholder: '',
            type: 'date',
            gridClass: 'col-span-4'
        },
        {
            name: 'transactionType',
            value: defaultValues.transactionType.value ?? '',
            placeholder: 'Tipo de Transação',
            label: 'Tipo de Transação',
            type: 'select',
            items: transactionTypes,
            order: 'A-Z',
            defaultItem: (defaultValues.transactionType !=  '') ? defaultValues.transactionType.name : 'Todas',
            gridClass: 'col-span-4',
        },
    ]

    return (
        <>
            <DefaultForm ref={ formRef } fields={ fields } onChange={ handleSubmit } />
        </>
    )
}

export default TransactionsForm

