import { useEffect, useState } from "react";
import { UseNews } from "./newsSearch";
import { useDispatch } from "react-redux";
import BasicTable from "components/BasicTable";
import Button from "components/form/Button";
import Tabs from "components/Tabs";
import Modal from "components/Modal";
import style from '../../../styles/UserTabs.module.css'
import styles from '../../../styles/Search.module.css'
import { Paths } from "paths/Paths";
import { fetchSelectedNewsDetails } from "store/modules/news/selectedNewsDetailSlice";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import Select from 'react-select'
import { deleteNews, getNewsCategories, saveNews, saveNewsCategories, saveNewsImg } from "services/newsService";
import { fetchNews } from "store/modules/news/newsSlice";
import { cleanNewsForDetails, selectNewsForDetails } from "store/modules/news/selectedNewsSlice";


export default function Details({ item }) {
    const newsHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const firstNewsData = UseNews(item);
    const [newsData, setNewsData] = useState(firstNewsData)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [handleValueChange, setHandleValueChange] = useState(true)
    const [newsCategories, setNewsCategories] = useState([])
    const [selectedNewsCategories, setSelectedNewsCategories] = useState([])
    const [isDeleteNews, setIsDeleteNews] = useState(false)

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        control: (provided, state) => ({
            ...provided,
            width: '20vw',
            borderColor: state.isFocused ? '#155e75' : '#d1d5db',
            '&:hover': {
                borderColor: '#d1d5db'
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#6b7280'
        })
    };

    useEffect(() => {
        let firstNewsDataCopy = { ...firstNewsData }
        setNewsData(firstNewsDataCopy)

        setOriginalValues({
            titlePt: firstNewsData.titlePt,
            shortTextPt: firstNewsData.shortTextPt,
            fullTextPt: firstNewsData.fullTextPt,
            newsImageUrlPt: firstNewsData.newsImageUrlPt,
            categoriesPt: firstNewsData.categoriesPt,
            titleEn: firstNewsData.titleEn,
            shortTextEn: firstNewsData.shortTextEn,
            fullTextEn: firstNewsData.fullTextEn,
            newsImageUrlEn: firstNewsData.newsImageUrlEn,
            categoriesEn: firstNewsData.categoriesEn,
            titleEs: firstNewsData.titleEs,
            shortTextEs: firstNewsData.shortTextEs,
            fullTextEs: firstNewsData.fullTextEs,
            newsImageUrlEs: firstNewsData.newsImageUrlEs,
            categoriesEs: firstNewsData.categoriesEs,
            newsId: firstNewsData.newsId,
            date: firstNewsData.date,
            authorName: firstNewsData.authorName,
            likes: firstNewsData.likes,
            categoriesLanguagePt: firstNewsData.categoriesLanguagePt,
            categoriesLanguageEn: firstNewsData.categoriesLanguageEn,
            categoriesLanguageEs: firstNewsData.categoriesLanguageEs,
        })

        setChangedValues({
            titlePt: firstNewsData.titlePt,
            shortTextPt: firstNewsData.shortTextPt,
            fullTextPt: firstNewsData.fullTextPt,
            newsImageUrlPt: firstNewsData.newsImageUrlPt,
            categoriesPt: firstNewsData.categoriesPt,
            titleEn: firstNewsData.titleEn,
            shortTextEn: firstNewsData.shortTextEn,
            fullTextEn: firstNewsData.fullTextEn,
            newsImageUrlEn: firstNewsData.newsImageUrlEn,
            categoriesEn: firstNewsData.categoriesEn,
            titleEs: firstNewsData.titleEs,
            shortTextEs: firstNewsData.shortTextEs,
            fullTextEs: firstNewsData.fullTextEs,
            newsImageUrlEs: firstNewsData.newsImageUrlEs,
            categoriesEs: firstNewsData.categoriesEs,
            newsId: firstNewsData.newsId,
            date: firstNewsData.date,
            authorName: firstNewsData.authorName,
            likes: firstNewsData.likes,
            categoriesLanguagePt: firstNewsData.categoriesLanguagePt,
            categoriesLanguageEn: firstNewsData.categoriesLanguageEn,
            categoriesLanguageEs: firstNewsData.categoriesLanguageEs,
        })

    }, [firstNewsData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];
            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");
        setIsModalOpen(false)
        setNewsCategories([]);
        setSelectedNewsCategories([]);
    }

    const handleSelectRow = async (item) => {
        if (item.name == "Categorias") {
            const tempCat = await getNewsCategories()
            let tempArr = []
            console.log(tempCat)
            tempCat.map((cat) => {
                tempArr.push({ value: cat._id, label: cat.name[item.language] })
            })
            setNewsCategories(tempArr)
        }
        if (item.name != "ID Notícia" && item.name != "Data" && item.name != "Autor" && item.name != "Likes") {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = () => {

        if (currentItem.language == "pt-BR") {
            let newsDataCopy = { ...newsData, tablePropertiesPt: [...newsData.tablePropertiesPt] };
            let changedValuesCopy = { ...changedValues }

            if (inputValue != '') {
                for (let i = 0; i < newsData.tablePropertiesPt.length; i++) {
                    if (newsData.tablePropertiesPt[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                        newsDataCopy.tablePropertiesPt[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                        newsDataCopy[currentItem.key] = inputValue;
                        changedValuesCopy[currentItem.key] = inputValue;
                    }
                }
                setChangedValues(changedValuesCopy)
                setNewsData(newsDataCopy);
            }
        }
        else if (currentItem.language == "en-US") {
            let newsDataCopy = { ...newsData, tablePropertiesEn: [...newsData.tablePropertiesEn] };
            let changedValuesCopy = { ...changedValues }

            if (inputValue != '') {
                for (let i = 0; i < newsData.tablePropertiesEn.length; i++) {
                    if (newsData.tablePropertiesEn[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                        newsDataCopy.tablePropertiesEn[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                        newsDataCopy[currentItem.key] = inputValue;
                        changedValuesCopy[currentItem.key] = inputValue;
                    }
                }
                setChangedValues(changedValuesCopy)
                setNewsData(newsDataCopy);
            }
        }
        else if (currentItem.language == "es-ES") {
            let newsDataCopy = { ...newsData, tablePropertiesEs: [...newsData.tablePropertiesEs] };
            let changedValuesCopy = { ...changedValues }

            if (inputValue != '') {
                for (let i = 0; i < newsData.tablePropertiesEs.length; i++) {
                    if (newsData.tablePropertiesEs[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                        newsDataCopy.tablePropertiesEs[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                        newsDataCopy[currentItem.key] = inputValue;
                        changedValuesCopy[currentItem.key] = inputValue;
                    }
                }
                setChangedValues(changedValuesCopy)
                setNewsData(newsDataCopy);
            }
        }


        setCurrentItem({});
        setInputValue("");
        handleCloseModal();

    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setNewsData(firstNewsData)
    }

    const dispatch = useDispatch()

    const handleSaveChanges = async () => {
        try {
            let originalValuesCopy = { ...originalValues }
            let changedValuesCopy = { ...changedValues }
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);


            const propertyApi = {
                titlePt: 'title.pt-BR',
                shortTextPt: 'shortText.pt-BR',
                fullTextPt: 'fullText.pt-BR',
                titleEn: 'title.en-US',
                shortTextEn: 'shortText.en-US',
                fullTextEn: 'fullText.en-US',
                titleEs: 'title.es-ES',
                shortTextEs: 'shortText.es-ES',
                fullTextEs: 'fullText.es-ES',
            }

            let changes = {}

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if (originalValues[propName] !== changedValues[propName]) {
                    changes[propertyApi[propName]] = changedValues[propName]
                }
            }

            await saveNews(item.newsId, changes)

            setChangedValues(changedValuesCopy)
            setOriginalValues(changedValuesCopy)

            fetchSelectedNewsDetails(dispatch, { newsId: item.newsId });
            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }

    }

    const handleFileSubmit = async (e) => {
        try {
            const file = e.target.files[0];

            await saveNewsImg(item.newsId, file, currentItem.language);

            await fetchSelectedNewsDetails(dispatch, { newsId: item.newsId });
            toast.success('Imagem Salva com Sucesso!');
        }
        catch {
            toast.error('Erro ao Salvar Imagem!');
        }
        handleCloseModal()
    }

    function areArraysEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }

        const sortedArr1 = arr1.sort((a, b) => a.id - b.id);
        const sortedArr2 = arr2.sort((a, b) => a.id - b.id);

        return sortedArr1.every((obj1, index) => {
            return JSON.stringify(obj1) === JSON.stringify(sortedArr2[index]);
        });
    }

    const handleSaveCategories = async (e) => {
        try {
            if (selectedNewsCategories.length !=0 && currentItem.language == 'pt-BR') {
                if (!areArraysEqual(selectedNewsCategories, originalValues.categoriesLanguagePt)) {

                    await saveNewsCategories(item.newsId, selectedNewsCategories);
                    await fetchSelectedNewsDetails(dispatch, { newsId: item.newsId });
                    toast.success('Categoria(s) Salva(s) com Sucesso!');
                }
            }
            else if (selectedNewsCategories.length !=0 && currentItem.language == 'en-US') {
                if (!areArraysEqual(selectedNewsCategories, originalValues.categoriesLanguageEn)) {

                    await saveNewsCategories(item.newsId, selectedNewsCategories);
                    await fetchSelectedNewsDetails(dispatch, { newsId: item.newsId });
                    toast.success('Categoria(s) Salva(s) com Sucesso!');
                }
            }
            else if (selectedNewsCategories.length !=0 && currentItem.language == 'es-ES') {
                if (!areArraysEqual(selectedNewsCategories, originalValues.categoriesLanguageEs)) {

                    await saveNewsCategories(item.newsId, selectedNewsCategories);
                    await fetchSelectedNewsDetails(dispatch, { newsId: item.newsId });
                    toast.success('Categoria(s) Salva(s) com Sucesso!');
                }
            }


        }
        catch {
            toast.error('Erro ao Salvar Categoria(s)!');
        }
        handleCloseModal()
    }

    const handleDeleteNews = async () => {
        try {
            await deleteNews(item.newsId)

            await fetchNews(dispatch, {}, 0);
            dispatch(cleanNewsForDetails())


            handleCloseDeleteNewsModal()
            toast.success('Notícia Removida com Sucesso!')
        }
        catch {
            toast.error('Erro ao Remover Notícia!')
        }
    }

    const handleOpenDeleteNewsModal = () => {
        setIsDeleteNews(true)
    }

    const handleCloseDeleteNewsModal = () => {
        setIsDeleteNews(false)
    }

    const tabs = []

    tabs.push(
        {
            id: 1,
            tabTitle: 'Propriedades - PT',
            content: (
                <>
                    <BasicTable
                        round={false}
                        height='58.5vh'
                        onSelectRow={handleSelectRow}
                        headers={newsHeaders}
                        items={newsData.tablePropertiesPt}
                        slotHeader={(handleValueChange) ? "" :
                            <div className={style.header}>
                                <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                                <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                            </div>
                        }
                    />
                    <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >
                        {(modalTitle != "Imagem") ?
                            <div className={style.modalContentArea}>
                                <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                                {
                                    (modalTitle != 'Categorias') ?
                                        (modalTitle != 'Título') ?
                                            <textarea className={`${style.modalInput} ${style.modalInputArea}`} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                            : <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                        :
                                        <Select
                                            classNamePrefix="select"
                                            onChange={(item) => setSelectedNewsCategories(item)}
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="categories"
                                            styles={customStyles}
                                            defaultValue={originalValues.categoriesLanguagePt}
                                            options={newsCategories}
                                            isMulti
                                            placeholder={"Selecione as categorias"}
                                        />


                                }
                                {
                                    modalTitle == 'Categorias' ?
                                        <button className={style.modalButton} onClick={handleSaveCategories}>Alterar</button>
                                        :
                                        <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                                }

                            </div>
                            :
                            <div className={style.modalImgContent}>
                                <img className={style.modalImg} src={`${Paths.mediaPath}${inputValue}`} />
                                <span className={style.modalImgLabel}>{`Nova Imagem para a Notícia:`}</span>
                                <input className={style.inputFile} type='file' accept=".png" onChange={handleFileSubmit} />

                            </div>}
                    </Modal>
                </>
            )
        },

        {
            id: 2,
            tabTitle: 'Propriedades - EN',
            content: (
                <>
                    <BasicTable
                        round={false}
                        height='58.5vh'
                        onSelectRow={handleSelectRow}
                        headers={newsHeaders}
                        items={newsData.tablePropertiesEn}
                        slotHeader={(handleValueChange) ? "" :
                            <div className={style.header}>
                                <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                                <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                            </div>
                        }
                    />
                    <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >
                        {(modalTitle != "Imagem") ?
                            <div className={style.modalContentArea}>
                                <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                                {
                                    (modalTitle != 'Categorias') ?
                                        (modalTitle != 'Título') ?
                                            <textarea className={`${style.modalInput} ${style.modalInputArea}`} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                            : <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                        :
                                        <Select
                                            classNamePrefix="select"
                                            onChange={(item) => setSelectedNewsCategories(item)}
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="categories"
                                            styles={customStyles}
                                            defaultValue={originalValues.categoriesLanguageEn}
                                            options={newsCategories}
                                            isMulti
                                            placeholder={"Selecione as categorias"}
                                        />


                                }
                                {
                                    modalTitle == 'Categorias' ?
                                        <button className={style.modalButton} onClick={handleSaveCategories}>Alterar</button>
                                        :
                                        <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                                }

                            </div>
                            :
                            <div className={style.modalImgContent}>
                                <img className={style.modalImg} src={`${Paths.mediaPath}${inputValue}`} />
                                <span className={style.modalImgLabel}>{`Nova Imagem para a Notícia:`}</span>
                                <input className={style.inputFile} type='file' accept=".png" onChange={handleFileSubmit} />

                            </div>}
                    </Modal>
                </>
            )
        },

        {
            id: 3,
            tabTitle: 'Propriedades - ES',
            content: (
                <>
                    <BasicTable
                        round={false}
                        height='58.5vh'
                        onSelectRow={handleSelectRow}
                        headers={newsHeaders}
                        items={newsData.tablePropertiesEs}
                        slotHeader={(handleValueChange) ? "" :
                            <div className={style.header}>
                                <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                                <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                            </div>
                        }
                    />
                    <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >
                        {(modalTitle != "Imagem") ?
                            <div className={style.modalContentArea}>
                                <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                                {
                                    (modalTitle != 'Categorias') ?
                                        (modalTitle != 'Título') ?
                                            <textarea className={`${style.modalInput} ${style.modalInputArea}`} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                            : <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                        :
                                        <Select
                                            classNamePrefix="select"
                                            onChange={(item) => setSelectedNewsCategories(item)}
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="categories"
                                            styles={customStyles}
                                            defaultValue={originalValues.categoriesLanguageEs}
                                            options={newsCategories}
                                            isMulti
                                            placeholder={"Selecione as categorias"}
                                        />


                                }
                                {
                                    modalTitle == 'Categorias' ?
                                        <button className={style.modalButton} onClick={handleSaveCategories}>Alterar</button>
                                        :
                                        <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                                }

                            </div>
                            :
                            <div className={style.modalImgContent}>
                                <img className={style.modalImg} src={`${Paths.mediaPath}${inputValue}`} />
                                <span className={style.modalImgLabel}>{`Nova Imagem para a Notícia:`}</span>
                                <input className={style.inputFile} type='file' accept=".png" onChange={handleFileSubmit} />

                            </div>}
                    </Modal>
                </>
            )
        },
    )


    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-auto p-2'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <div className='p-2 text-sm' style={{ display: 'flex', margin: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <b>{item.title}</b>
                            <p>{item.newsId}</p>
                        </div>
                        {<Button text="Excluir Notícia" color="cyan" onClick={handleOpenDeleteNewsModal} />}
                    </div>
                )}
            />
            <Modal
                header={`Atenção!`}
                isOpen={isDeleteNews}
                onClose={handleCloseDeleteNewsModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>Deseja apagar permanentemente a notícia?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDeleteNews} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeleteNewsModal} color="cyan" />
                    </div>
                </div>
            </Modal>
        </div>
    )
}