import { newsCategoriesMapping, newsMapping } from 'domains/News/mapping';
import api, { apiNormalize } from './api'
import News from 'domains/News/News';
import NewsDetail from 'domains/News/NewsDetail';
import handleRequestError from 'helpers/apiErrorHandler';
import NewsCategory from 'domains/News/NewsCategory';
import NewsCategoryDetail from 'domains/News/NewsCategoryDetail';

export async function getNews(params, skip) {
    const response = await api.get("/news", { params: { ...params, skip } });
    const news = apiNormalize.responseMapping(response.data, newsMapping)
    const normalizedNews = news.map(oneNews => new News(oneNews).get());
    console.log(normalizedNews)
    return normalizedNews
}

export async function getSelectedNewsDetails(params) {
    const response = await api.get("/news", { params: { ...params, skip: 0 } });
    const news = apiNormalize.responseMapping(response.data, newsMapping)
    const normalizedNews = news.map(oneNews => new NewsDetail(oneNews).get());
    console.log(normalizedNews)
    return normalizedNews[0]
}

export async function getNewsCategories() {
    const response = await api.get("/newsCategories");
    console.log(response)
    return response.data
}

export async function getNewsCategoriesSkip(skip, id = null) {
    const response = await api.get("/newsCategories/skip", {params: {skip, id}});

    const newsCategories = apiNormalize.responseMapping(response.data, newsCategoriesMapping)
    const normalizedNewsCategories = newsCategories.map(newsCategory => new NewsCategory(newsCategory).get());
    return normalizedNewsCategories
}

export async function getSelectedNewsCategoryDetails(skip, id = null) {
    const response = await api.get("/newsCategories/skip", {params: {skip, id}});

    const newsCategories = apiNormalize.responseMapping(response.data, newsCategoriesMapping)
    const normalizedNewsCategories = newsCategories.map(newsCategory => new NewsCategoryDetail(newsCategory).get());
    return normalizedNewsCategories[0]
}


export async function getNewsCatList() {
    const response = await api.get("/newsCategories");
    let tempArr = []
    response.data.map((cat) => {
        tempArr.push({ value: cat._id, label: cat.name['pt-BR'] })
    })
    return tempArr
}


export const saveNewsCategories = async (newsId, changes) => {
    try {
        const response = await api.post(`/news/${newsId}/saveNewsCategories`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const saveNewsCategory = async (newsCategoryId, changes) => {
    try {
        const response = await api.post(`/newsCategories/${newsCategoryId}/saveNewsCategory`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const saveNewsImg = async (newsId, img, language) => {
    try {
        const formData = new FormData();
        formData.append('img', img);
        const response = await api.post(`/news/${newsId}/saveNewsImg/${language}`, formData);
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const saveNews = async (newsId, changes) => {
    try {
        const response = await api.post(`/news/${newsId}/saveNews`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deleteNews = async (newsId) => {
    try {
        const response = await api.post(`/news/${newsId}/deleteNews`)
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deleteNewsCategory = async (newsCategoryId) => {
    try {
        const response = await api.post(`/newsCategories/${newsCategoryId}/deleteNewsCategory`)
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const createNews = async (changes, imgs) => {
    try {
        const formData = new FormData();

        Object.keys(imgs).forEach((key, index) => {
            const img = imgs[key];
            formData.append(`img[]`, img);
        });
        
        formData.append('changes', JSON.stringify(changes));

        const response = await api.post(`/news/createNews`, formData);
        return response.data;
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const createNewsCategory = async (changes) => {
    try {
        const response = await api.post(`/newsCategories/createNewsCategory`, {changes});
        return response.data;
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}