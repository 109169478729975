class NewsCategory {
    constructor({newsCategoryId, name}){
        this.newsCategoryId = newsCategoryId
        this.name = name
    }

    get() {
        return {
            smallNewsCategoryId: this.smallId(this.newsCategoryId),
            newsCategoryId: this.newsCategoryId,
            ptName: this.name['pt-BR'],
            enName: this.name['en-US'],
            esName: this.name['es-ES'],
        }
    }

    smallId(id) {
        return id?.slice(0, 7)
    }
}

export default NewsCategory