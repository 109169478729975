class NewsCategoryDetail {
    constructor({newsCategoryId, name}){
        this.newsCategoryId = newsCategoryId
        this.name = name
    
    }

    get() {
        return {
            tableProperties: [
                {
                    key: 'newsCategoryId',
                    name: "ID Categoria",
                    value: this.newsCategoryId,
                },
                {
                    key: 'ptName',
                    name: "Nome em PT",
                    value: this.name['pt-BR'],
                },
                {
                    key: 'enName',
                    name: "Nome em EN",
                    value: this.name['en-US'],
                },
                {
                    key: 'esName',
                    name: "Nome em ES",
                    value: this.name['es-ES'],
                },
            ],
            newsCategoryId: this.newsCategoryId,
            ptName: this.name['pt-BR'],
            enName: this.name['en-US'],
            esName: this.name['es-ES'],
        }
    }
}

export default NewsCategoryDetail