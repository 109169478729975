import React, { useState } from 'react'
import InputField from 'components/form/InputField'
import Checkbox from 'components/form/Checkbox'
import RadioButton from 'components/form/RadioButton'
function EmailFilters () {
    const [ownerId, setOwnerID] = useState('')
    const [selectedOption, setSelectedOption] = useState('')

    const options = [
        { value: 'option1', label: 'Usuário selecionado' }
    ]

    const handleOwnerIDChange = (event) => {
        setOwnerID(event.target.value)
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value)
    }

    return (
        <div className='border-b'>
            <div className='flex'>
                <InputField type="text" label="ID do Dono" placeholder="ID do Dono" value={ ownerId } onChange={ handleOwnerIDChange } />
                <RadioButton
                    options={ options }
                    selectedOption={ selectedOption }
                    onChange={ handleOptionChange }
                />
            </div>
        </div>
    )
}

import { uuidMask } from 'helpers/masks'
import DefaultForm from 'components/form/DefaultForm'
import { useDispatch } from 'react-redux'
import { fetchEmailsFilters } from 'store/modules/users/emails/emailsSlice'

function EmailsFilter ({onFilterChange, defaultValues}) {

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        

        if(name == 'emailStatus' || name == 'emailType' || name == 'receiverType' || name == 'senderType'){
            let propName = {
                N: 'Não lido',
                R: 'Lido',
                S: 'Sistema',
                U: 'Usuário',
                A: 'Avatar'
            }
            newValue[name] = {name: propName[value], value};
        }
        else{
            newValue[name] = value;
        }

        fetchEmailsFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'emailId',
            label: 'ID do Email',
            value: defaultValues.emailId,
            placeholder: 'ID do Email',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'emailStatus',
            label: 'Status do Email',
            value: defaultValues.emailStatus.value ?? '',
            placeholder: 'Status do Email',
            type: 'select',
            items: [
                {
                    value: '',
                    name: 'Todos'
                },
                {
                    value: 'N',
                    name: 'Não lido'
                },
                {
                    value: 'R',
                    name: 'Lido'
                }
            ],
            order: 'A-Z',
            defaultItem: (defaultValues.emailStatus !=  '') ? defaultValues.emailStatus.name : ' Todos ',
            gridClass: 'col-span-3',
        },
        {
            name: 'emailType',
            label: 'Tipo de Email',
            value: defaultValues.emailType.value ?? '',
            placeholder: 'Tipo de Email',
            type: 'select',
            items: [
                {
                    value: '',
                    name: 'Todos'
                },
                {
                    value: 'S',
                    name: 'Sistema'
                },
                {
                    value: 'U',
                    name: 'Usuário'
                },
                {
                    value: 'A',
                    name: 'Avatar'
                }
            ],
            order: 'A-Z',
            defaultItem: (defaultValues.emailType !=  '') ? defaultValues.emailType.name : 'Todos',
            gridClass: 'col-span-3',
        },
        {
            name: 'senderId',
            label: 'ID do Remetente',
            value: defaultValues.senderId,
            placeholder: 'ID do Remetente',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'receiverId',
            label: 'ID do Destinatário',
            value: defaultValues.receiverId,
            placeholder: 'ID do Destinatário',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'senderType',
            label: 'Tipo de Remetente',
            value: defaultValues.senderType.value ?? '',
            placeholder: 'Tipo de Remetente',
            type: 'select',
            items: [
                {
                    value: '',
                    name: 'Todos'
                },
                {
                    value: 'S',
                    name: 'Sistema'
                },
                {
                    value: 'U',
                    name: 'Usuário'
                },
                {
                    value: 'A',
                    name: 'Avatar'
                }
            ],
            order: 'A-Z',
            defaultItem: (defaultValues.senderType !=  '') ? defaultValues.senderType.name : 'Todos',
            gridClass: 'col-span-3',
        },
        {
            name: 'receiverType',
            label: 'Tipo de Destinatário',
            value: defaultValues.senderType.value ?? '',
            placeholder: 'Tipo de Destinatário',
            type: 'select',
            items: [
                {
                    value: '',
                    name: 'Todos'
                },
                {
                    value: 'S',
                    name: 'Sistema'
                },
                {
                    value: 'U',
                    name: 'Usuário'
                },
                {
                    value: 'A',
                    name: 'Avatar'
                }
            ],
            order: 'A-Z',
            defaultItem: (defaultValues.receiverType !=  '') ? defaultValues.receiverType.name : 'Todos',
            gridClass: 'col-span-3',
        },
        {
            name: 'emailSubject',
            label: 'Assunto do Email',
            value: defaultValues.emailSubject,
            placeholder: 'Assunto do Email',
            type: 'text',
            validate: [],
            gridClass: 'col-span-3'
        },
        {
            name: 'emailText',
            label: 'Texto no Email',
            value: defaultValues.emailText,
            placeholder: 'Texto no Email',
            type: 'text',
            validate: [],
            gridClass: 'col-span-3'
        },
    ]
    

    return (
        <>
            <DefaultForm fields={ fields } onChange={handleSubmit} />
        </>
    )
}

export {EmailFilters, EmailsFilter}
