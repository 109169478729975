import React from 'react'
import DefaultForm from 'components/form/DefaultForm'
import { useDispatch } from 'react-redux'
import { fetchStoreLogFilters } from 'store/modules/moderation/storeLog/storeLogSlice'
import { uuidMask } from 'helpers/masks'

const StoreLogsFilter = ({ onFilterChange, defaultValues }) => {

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({ form: formData, isValid: isValid })

        let newValue = {};
        if (name == 'paymentMethod' || name == 'status') {
            let propName = {
                'xsolla': 'Xsolla',
                'pagarme': 'Pagrame',
                'paid': 'Sim',
                'unpaid': 'Não',
            }
            newValue[name] = { name: propName[value], value };
        }
        else {
            newValue[name] = value;
        }

        fetchStoreLogFilters(dispatch, { ...defaultValues, ...newValue })
    }

    const paymentTypes = [
        { value: '', name: 'Todos' },
        { value: 'xsolla', name: 'Xsolla' },
        { value: 'pagarme', name: 'Pagarme' },
    ]

    const statusTypes = [
        { value: '', name: 'Todos' },
        { value: 'paid', name: 'Sim' },
        { value: 'unpaid', name: 'Não' },
    ]

    const fields = [
        {
            name: 'purchaseId',
            label: 'ID da Compra',
            value: defaultValues.purchaseId,
            placeholder: 'ID da Compra',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'userId',
            label: 'ID do Usuário',
            value: defaultValues.userId,
            placeholder: 'ID do Usuário',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'status',
            value: defaultValues.status.value ?? '',
            placeholder: 'Pago',
            label: 'Pago',
            type: 'select',
            items: statusTypes,
            order: 'A-Z',
            defaultItem: (defaultValues.status != '') ? defaultValues.status.name : 'Todos',
            gridClass: 'col-span-6',
        },
        {
            name: 'paymentMethod',
            value: defaultValues.paymentMethod.value ?? '',
            placeholder: 'Método de Pagamento',
            label: 'Método de Pagamento',
            type: 'select',
            items: paymentTypes,
            order: 'A-Z',
            defaultItem: (defaultValues.paymentMethod != '') ? defaultValues.paymentMethod.name : 'Todos',
            gridClass: 'col-span-6',
        },
        {
            name: 'dateFrom',
            label: 'De',
            value: defaultValues.dateFrom,
            placeholder: '',
            type: 'date',
            gridClass: 'col-span-6'
        },
        {
            name: 'dateTo',
            label: 'Até',
            value: defaultValues.dateTo,
            placeholder: '',
            type: 'date',
            gridClass: 'col-span-6'
        },
    ]

    return (
        <>
            <DefaultForm fields={fields} onChange={handleSubmit} />
        </>
    )
}

export default StoreLogsFilter

