import moment from "moment"

class NewsDetail {
    constructor({ newsId, title, shortText, fullText, newsImageUrl, date, authorId, authorFirstName, authorLastName, authorNameInstance, authorIcon, categories, likes }) {
        this.newsId = newsId
        this.title = title
        this.shortText = shortText
        this.fullText = fullText
        this.newsImageUrl = newsImageUrl
        this.date = date
        this.authorId = authorId
        this.authorFirstName = authorFirstName
        this.authorLastName = authorLastName
        this.authorNameInstance = authorNameInstance
        this.authorIcon = authorIcon
        this.categories = categories
        this.likes = likes
    }

    get() {
        return {
            tablePropertiesPt: [
                {
                    key: 'newsId',
                    name: "ID Notícia",
                    value: this.newsId,
                    language: "pt-BR"
                },
                {
                    key: 'date',
                    name: "Data",
                    value: moment(this.date).format("DD/MM/YYYY"),
                    language: "pt-BR"
                },
                {
                    key: 'author',
                    name: "Autor",
                    value: this.avatarFullName(this.authorNameInstance, this.authorFirstName, this.authorLastName),
                    language: "pt-BR"
                },
                {
                    key: 'likes',
                    name: "Likes",
                    value: this.likes,
                    language: "pt-BR"
                },
                {
                    key: 'imagePt',
                    name: "Imagem",
                    value: this.newsImageUrl['pt-BR'],
                    language: "pt-BR"
                },
                {
                    key: 'titlePt',
                    name: "Título",
                    value: this.title['pt-BR'],
                    language: "pt-BR"
                },
                {
                    key: 'shortTextPt',
                    name: "Texto Curto",
                    value: this.shortText['pt-BR'],
                    language: "pt-BR"
                },
                {
                    key: 'fullTextPt',
                    name: "Texto Completo",
                    value: this.fullText['pt-BR'],
                    language: "pt-BR"
                },
                {
                    key: 'categoriesPt',
                    name: "Categorias",
                    value: this.categoriesNameLanguage(this.categories, 'pt-BR').length > 0 ? this.categoriesNameLanguage(this.categories, 'pt-BR') : '-',
                    language: "pt-BR"
                }
            
            ],
            tablePropertiesEn: [
                {
                    key: 'newsId',
                    name: "ID Notícia",
                    value: this.newsId,
                    language: "en-US"
                },
                {
                    key: 'date',
                    name: "Data",
                    value: moment(this.date).format("DD/MM/YYYY"),
                    language: "en-US"
                },
                {
                    key: 'author',
                    name: "Autor",
                    value: this.avatarFullName(this.authorNameInstance, this.authorFirstName, this.authorLastName),
                    language: "en-US"
                },
                {
                    key: 'likes',
                    name: "Likes",
                    value: this.likes,
                    language: "en-US"
                },
                {
                    key: 'imageEn',
                    name: "Imagem",
                    value: this.newsImageUrl['en-US'],
                    language: "en-US"
                },
                {
                    key: 'titleEn',
                    name: "Título",
                    value: this.title['en-US'],
                    language: "en-US"
                },
                {
                    key: 'shortTextEn',
                    name: "Texto Curto",
                    value: this.shortText['en-US'],
                    language: "en-US"
                },
                {
                    key: 'fullTextEn',
                    name: "Texto Completo",
                    value: this.fullText['en-US'],
                    language: "en-US"
                },
                {
                    key: 'categoriesEn',
                    name: "Categorias",
                    value: this.categoriesNameLanguage(this.categories, 'en-US').length > 0 ? this.categoriesNameLanguage(this.categories, 'en-US') : '-',
                    language: "en-US"
                }
            
            ],
            tablePropertiesEs: [
                {
                    key: 'newsId',
                    name: "ID Notícia",
                    value: this.newsId,
                    language: "es-ES"
                },
                {
                    key: 'date',
                    name: "Data",
                    value: moment(this.date).format("DD/MM/YYYY"),
                    language: "es-ES"
                },
                {
                    key: 'author',
                    name: "Autor",
                    value: this.avatarFullName(this.authorNameInstance, this.authorFirstName, this.authorLastName),
                    language: "es-ES"
                },
                {
                    key: 'likes',
                    name: "Likes",
                    value: this.likes,
                    language: "es-ES"
                },
                {
                    key: 'imageEs',
                    name: "Imagem",
                    value: this.newsImageUrl['es-ES'],
                    language: "es-ES"
                },
                {
                    key: 'titleEs',
                    name: "Título",
                    value: this.title['es-ES'],
                    language: "es-ES"
                },
                {
                    key: 'shortTextEs',
                    name: "Texto Curto",
                    value: this.shortText['es-ES'],
                    language: "es-ES"
                },
                {
                    key: 'fullTextEs',
                    name: "Texto Completo",
                    value: this.fullText['es-ES'],
                    language: "es-ES"
                },
                {
                    key: 'categoriesEs',
                    name: "Categorias",
                    value: this.categoriesNameLanguage(this.categories, 'es-ES').length > 0 ? this.categoriesNameLanguage(this.categories, 'es-ES') : '-',
                    language: "es-ES"
                }
            
            ],
            titlePt: this.title['pt-BR'],
            shortTextPt: this.shortText['pt-BR'],
            fullTextPt: this.fullText['pt-BR'],
            newsImageUrlPt: this.newsImageUrl['pt-BR'],
            categoriesPt: this.categoriesNameLanguage(this.categories, 'pt-BR'),

            titleEn: this.title['en-US'],
            shortTextEn: this.shortText['en-US'],
            fullTextEn: this.fullText['en-US'],
            newsImageUrlEn: this.newsImageUrl['en-US'],
            categoriesEn: this.categoriesNameLanguage(this.categories, 'en-US'),

            titleEs: this.title['es-ES'],
            shortTextEs: this.shortText['es-ES'],
            fullTextEs: this.fullText['es-ES'],
            newsImageUrlEs: this.newsImageUrl['es-ES'],
            categoriesEs: this.categoriesNameLanguage(this.categories, 'es-ES'),
            
            likes: this.likes,
            newsId: this.newsId,
            categoriesLanguagePt: this.categoriesLanguage(this.categories, 'pt-BR'),
            categoriesLanguageEn: this.categoriesLanguage(this.categories, 'en-US'),
            categoriesLanguageEs: this.categoriesLanguage(this.categories, 'es-ES'),
            date: moment(this.date).format("DD/MM/YYYY"),
            authorId: this.authorId,
            authorFirstName: this.authorFirstName,
            authorLastName: this.authorLastName,
            authorName: this.avatarFullName(this.authorNameInstance, this.authorFirstName, this.authorLastName),
            authorNameInstance: this.authorNameInstance,
        }
    }


    avatarFullName(avatarNameInstance, avatarFirstName, avatarLastName) {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (avatarNameInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarNameInstance);
        else if (avatarFirstName === null || avatarLastName === null) return "Avatar Apagado"
        else return avatarFirstName + " " + avatarLastName;
    }

    categoriesNameLanguage(cat, lan){
        let tempArr = []
        for(let i = 0; i<cat.length; i++){
            tempArr.push(cat[i].name[lan])
        }
        return tempArr.join(' / ');
    }

    categoriesLanguage(cat, lan){
        let tempArr = []
        for(let i = 0; i<cat.length; i++){
            tempArr.push({value: cat[i]._id, label: cat[i].name[lan]})
        }
        return tempArr;
    }
}

export default NewsDetail