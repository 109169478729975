class ModelItem {
    constructor({ id, modelId, modelName, modelDetails, modelTags, goldPrice, tokenPrice, modelIcon, modelXml }) {
        this.id = id
        this.modelId = modelId
        this.modelName = modelName
        this.modelDetails = modelDetails
        this.modelTags = modelTags
        this.goldPrice = goldPrice
        this.tokenPrice = tokenPrice
        this.modelIcon = modelIcon
        this.modelXml = modelXml
    }

    get() {
        return {
            id: this.id,
            modelId: this.modelId,
            modelName: this.modelName,
            modelDetails: this.modelDetails,
            modelTags: this.modelTags,
            goldPrice: this.goldPrice,
            tokenPrice: this.tokenPrice,
            modelIcon: this.modelIcon,
            modelXml: this.modelXml
        }
    }
}

export default ModelItem