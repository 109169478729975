import ModelItems from "Pages/Items/ModelItems";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchModelItemsFilters } from "store/modules/items/model/modelItemsSlice";

export function ModelItemsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const modelId = searchParams.get("modelId");
    const modelDesc = searchParams.get("modelDesc");
    const modelDetails = searchParams.get("modelDetails");

    const dispatch = useDispatch()

    if (modelId || modelDesc || modelDetails) {
        fetchModelItemsFilters(dispatch, {modelId, modelDesc, modelDetails})
        return <ModelItems modelId={modelId ?? null} modelDesc={modelDesc ?? null} modelDetails={modelDetails ?? null}/>;
    }
    else{
        return <ModelItems/>;
    }

}