import moment, { duration } from "moment"

class AvatarDetail {
    constructor({ avatarId, avatarFirstName, avatarLastName, avatarNameInstance, timeInSpaces }) {
        this.avatarId = avatarId
        this.avatarFirstName = avatarFirstName
        this.avatarLastName = avatarLastName
        this.avatarNameInstance = avatarNameInstance
        this.timeInSpaces = timeInSpaces
    }

    get() {
        return {
            tableProperties: [
                {
                    key: 'avatarId',
                    name: "ID do Avatar",
                    value: this.avatarId,
                },
                {
                    key: 'avatarFirstName',
                    name: "Nome do Avatar",
                    value: this.avatarFirstName,
                },
                {
                    key: 'avatarLastName',
                    name: "Sobrenome do Avatar",
                    value: this.avatarLastName,
                },
                {
                    key: 'avatarNameInstance',
                    name: "Instância do Avatar",
                    value: this.avatarNameInstance,
                },
                {
                    key: 'duration',
                    name: "Tempo Total em Espaços",
                    value: this.formatDuration,
                }
            ],
            avatarId: this.avatarId,
            avatarFirstName: this.avatarFirstName,
            avatarLastName: this.avatarLastName,
            avatarNameInstance: this.avatarNameInstance,
            timeInSpaces: this.timeInSpaces,
            duration: this.formatDuration
        }
    }

    get formatDuration() {
        const duration = moment.duration(this.timeInSpaces, 'seconds');
    
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.hours());
    const minutes = Math.floor(duration.minutes());
    const seconds = Math.floor(duration.seconds());

    let result = '';

    if (days > 0) {
        result += `${days} dia(s) `;
    }
    if (hours > 0 || days > 0) {
        result += `${hours} hora(s) `;
    }
    if (minutes > 0 || hours > 0 || days > 0) {
        result += `${minutes} minuto(s) `;
    }
    result += `${seconds} segundo(s)`;

    return result.trim();
    };
}

export default AvatarDetail