import { getStoreLogs } from "services/storeLogsService";

const { createSlice } = require("@reduxjs/toolkit");

const storeLogSlice = createSlice({
    name: 'storeLog',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            userId: '',
            dateFrom: '',
            dateTo: '',
            status: '',
            paymentMethod: '',
            purchaseId: '',
        }
    },
    reducers: {
        setStoreLogData: (state, action) => {
            return {...state, data: action.payload}
        },
        addStoreLogData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setStoreLogFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchStoreLog(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const storeLog = await getStoreLogs(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addStoreLogData(storeLog))
        }
        else{
            dispatch(setStoreLogData(storeLog));
        }
        
        if(storeLog.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter StoreLog', error)
    }
}

export async function fetchStoreLogFilters(dispatch, params){
    dispatch(setStoreLogFilter(params));
}

export const { setStoreLogData, addStoreLogData, setCurrentSkip, setHasMore, setStoreLogFilter } = storeLogSlice.actions
export default storeLogSlice.reducer