import handleRequestError from "helpers/apiErrorHandler";
import api from "./api";

export const createBroadcastNotification = async (message, type) => {
    try {
        const response = await api.post(`/createBroadcastNotification`, {params: {message, type}});
        return response.data;
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}