import React, { useState, useEffect } from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../../../styles/Search.module.css'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import { fetchStoreLog } from 'store/modules/moderation/storeLog/storeLogSlice'
import moment from 'moment'
import StoreLogsFilter from './Partials/StoreLogsFilter'

export default function StoreLogs({purchaseId, userId, dateFrom, dateTo, status, paymentMethod }) {
    const verifyStoreLog = useUserPermission(SMIPermissionsCode.MOD_ADMIN);

    const [storeLogsHeaders, setStoreLogsHeaders] = useState([
        { title: 'Data', key: 'date', appear: true },
        { title: 'ID da Compra', key: 'smallPurchaseId', copy: 'purchaseId', appear: true },
        { title: 'Nome do Usuário', key: 'userName', copy: 'userId', appear: true },
        { title: 'Pago', key: 'status', appear: true },
        { title: 'Modo de Pagamento', key: 'paymentMethod', appear: true },
        { title: 'Pacotes', key: 'packages', appear: true },
    ]);

    const [storeLogsMomentHeaders, setStoreLogsMomentHeaders] = useState([...storeLogsHeaders]);

    const handleAppearHeader = (item) => {
        setStoreLogsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setStoreLogsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const storeLogs = useSelector((state) => {
        return Array.isArray(state.storeLogs.data) ? state.storeLogs.data : []
    })

    const skip = useSelector((state) => {
        return (state.storeLogs.currentSkip) ? state.storeLogs.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.storeLogs.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.storeLogs.filters) ? state.storeLogs.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [storeLogs]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (purchaseId || userId || dateFrom || dateTo || status || paymentMethod) {
            setLoading(true)
            fetchStoreLog(dispatch, { purchaseId, userId, dateFrom, dateTo, status, paymentMethod }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.purchaseId) query.append('purchaseId', filter.form.purchaseId);
        if (filter.form.userId) query.append('userId', filter.form.userId);
        if (filter.form.dateFrom) query.append('dateFrom', filter.form.dateFrom);
        if (filter.form.dateTo) query.append('dateTo', filter.form.dateTo);
        if (filter.form.status) query.append('status', filter.form.status);
        if (filter.form.paymentMethod) query.append('paymentMethod', filter.form.paymentMethod);
        
        window.history.pushState({}, '', `/moderation/log/store?${query.toString()}`);
        fetchStoreLog(dispatch, { ...filter.form, dateTo: moment(filter.form.dateTo).utc().format(), dateFrom: moment(filter.form.dateFrom).utc().format() }, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchStoreLog(dispatch, filter.form, skip + 200);
    })


    return (
        <div className='grid grid-cols-12 gap-4'>

            {verifyStoreLog && <div className='col-span-12'>
                <BasicTable
                    onAppearHeader={handleAppearHeader}
                    headerControl={true}
                    originalHeaders={storeLogsHeaders}
                    headers={storeLogsMomentHeaders}
                    slotHeader={<StoreLogsFilter onFilterChange={handleSetFilter} defaultValues={filters} />}
                    title='Buscar Logs da Loja'
                    isLoading={isLoading && storeLogs.length === 0}
                    slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                    items={storeLogs}
                    slotFooter={
                        <div className={styles.slotFooter}>
                            <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                            {(storeLogs.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : storeLogs.length} resultados</span>}
                        </div>
                    }
                />
            </div>}
        </div>
    )
}
