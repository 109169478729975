import DefaultForm from "components/form/DefaultForm"
import { uuidMask } from "helpers/masks"
import { useDispatch } from "react-redux";
import { fetchTournamentsFilters } from "store/modules/tournaments/tournamentsSlice";

export default function TournamentsFilter({onFilterChange, defaultValues}){
    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        newValue[name] = value;

        fetchTournamentsFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'tournamentId',
            label: 'ID do Torneio',
            value: defaultValues.tournamentId,
            placeholder: 'ID do Torneio',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'gameId',
            label: 'ID do jogo',
            value: defaultValues.gameId,
            placeholder: 'ID do jogo',
            type: 'text',
            validate: [],
            gridClass: 'col-span-6'
        },
    ]

    return <DefaultForm fields={fields} onChange={handleSubmit}/>
}