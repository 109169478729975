import styles from '../../styles/Search.module.css'
import styleTabs from '../../styles/UserTabs.module.css'
import style from '../../styles/News.module.css'
import BasicTable from "components/BasicTable";
import Button from "components/form/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewsCategories } from "store/modules/news/newsCategoriesSlice";
import { selectNewsCategoryForDetails } from 'store/modules/news/selectedNewsCategorySlice';
import CategoryDetails from './Partials/CategoryDetails';
import { toast } from 'react-toastify';
import { createNewsCategory } from 'services/newsService';
import Modal from 'components/Modal';
import useUserPermission from 'domains/Permissions/useUserPermission';
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode';

export default function NewsCategories() {
    const verifyNews = useUserPermission(SMIPermissionsCode.NEWS_MANAGE);

    const [newsCategoriesHeaders, setNewsCategoriesHeaders] = useState([
        { title: 'ID da Categoria', key: 'smallNewsCategoryId', copy: 'newsCategoryId', detailable: 'true', appear: true },
        { title: 'Nome em PT', key: 'ptName', appear: true },
        { title: 'Nome em EN', key: 'enName', appear: true },
        { title: 'Nome em ES', key: 'esName', appear: true },
    ]);

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [nameValueInput, setNameValueInput] = useState({ 'pt-BR': '', 'en-US': '', 'es-ES': '' })
    const [isFull, setIsFull] = useState(false);

    const [newsCategoriesMomentHeaders, setNewsCategoriesMomentHeaders] = useState([...newsCategoriesHeaders]);

    const handleAppearHeader = (item) => {
        setNewsCategoriesHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setNewsCategoriesMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }

    const [isLoading, setLoading] = useState(false)

    const newsCategories = useSelector((state) => {
        return Array.isArray(state.newsCategories.data) ? state.newsCategories.data : []
    })

    const selectedNewsCategory = useSelector((state) => {
        return state.selectedNewsCategory
    })

    const skip = useSelector((state) => {
        return (state.newsCategories.currentSkip) ? state.newsCategories.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.newsCategories.hasMore)
    })

    useEffect(() => {
        setLoading(false)
    }, [newsCategories]);

    const dispatch = useDispatch()

    const search = () => {
        setLoading(true)
        fetchNewsCategories(dispatch, 0);
    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchNewsCategories(dispatch, skip + 200);
    })

    const handleNewsCategoriesDetails = (item) => {
        console.log(item)
        dispatch(selectNewsCategoryForDetails(item.newsCategoryId))
    }

    const handleOpenCreateModal = async () => {
        setIsCreateModalOpen(true)
    }

    const handleCloseCreateModal = () => {
        setNameValueInput({ 'pt-BR': '', 'en-US': '', 'es-ES': '' })
        setIsFull(false);
        setIsCreateModalOpen(false)
    }

    const handleName = (e, lan) => {
        let tempName = { ...nameValueInput, [lan]: e.target.value }
        setNameValueInput(tempName)
    }

    const areAllFieldsFilled = (inputObject) => {
        return Object.values(inputObject).every(value => value !== '');
    };

    useEffect(() => {
        if (areAllFieldsFilled(nameValueInput)) {
            setIsFull(true)
        }
        else {
            setIsFull(false)
        }

    }, [nameValueInput])

    const handleCreateNewsCategory = async () => {
        try {
            await createNewsCategory(nameValueInput)
            handleCloseCreateModal();
            setLoading(true)
            fetchNewsCategories(dispatch, 0);
            toast.success("Dados Salvos com Sucesso!")
        }
        catch {
            toast.error("Erro ao Salvar Dados!")
        }
    }

    return (
        <div className='grid grid-cols-12 gap-4'>

            { verifyNews && <div className='col-span-7'>
                <BasicTable
                    onAppearHeader={handleAppearHeader}
                    headerControl={true}
                    originalHeaders={newsCategoriesHeaders}
                    headers={newsCategoriesMomentHeaders}
                    title='Buscar Categorias'
                    onDetailRow={handleNewsCategoriesDetails}
                    isLoading={isLoading && newsCategories.length === 0}
                    slotActions={
                        <>
                            <Button text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />
                            <Button text="Criar Categoria" onClick={handleOpenCreateModal} color="cyan" />
                        </>
                    }
                    items={newsCategories}
                    height='60vh'
                    slotFooter={
                        <div className={styles.slotFooter}>
                            <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                            {(newsCategories.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : newsCategories.length} resultados</span>}
                        </div>
                    }
                />
                <Modal
                    header={``}
                    isOpen={isCreateModalOpen}
                    onClose={handleCloseCreateModal}
                    footer={<div className={style.modalFooter}><Button disabled={!isFull} text="Salvar" onClick={handleCreateNewsCategory} color="cyan" /></div>}
                >
                    <div className={style.createNewsCategoryContainer}>
                        <div className={style.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Nome em PT`}</span>
                            <input className={style.modalInput} type="text" placeholder={`Nome em PT`} value={nameValueInput['pt-BR']} onChange={(e) => handleName(e, 'pt-BR')} />
                        </div>
                        <div className={style.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Nome em EN`}</span>
                            <input className={style.modalInput} type="text" placeholder={`Nome em EN`} value={nameValueInput['en-US']} onChange={(e) => handleName(e, 'en-US')} />
                        </div>
                        <div className={style.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Nome em ES`}</span>
                            <input className={style.modalInput} type="text" placeholder={`Nome em ES`} value={nameValueInput['es-ES']} onChange={(e) => handleName(e, 'es-ES')} />
                        </div>
                    </div>

                </Modal>
            </div>}
            <div className='col-span-5'>
                {selectedNewsCategory.newsCategoryId && <CategoryDetails item={selectedNewsCategory} />}
            </div>
        </div>
    )
}