import { getWidgets } from "services/widgetService";

const { createSlice } = require("@reduxjs/toolkit");

const widgetsSlice = createSlice({
    name:"widgets",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            widgetId: '',
            itemId: '',
            modelId: '',
            creatorAvatarId: ''
        }
    },
    reducers: {
        setWidgetsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addWidgetsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setWidgetsFilter: (state, action) => {
            return {...state, filters: action.payload}
        }
    },
})

export async function fetchWidgets(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const widgets = await getWidgets(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addWidgetsData(widgets))
        }
        else{
            dispatch(setWidgetsData(widgets));
        }
        
        if(widgets.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Widgets', error)
        dispatch(setCurrentSkip(0));
        dispatch(setWidgetsData([]));
        dispatch(setHasMore(false));
    }
}

export async function fetchWidgetsFilters(dispatch, params){
    dispatch(setWidgetsFilter(params));
}

export const { setWidgetsData, setCurrentSkip, setHasMore, addWidgetsData, setWidgetsFilter } = widgetsSlice.actions
export default widgetsSlice.reducer