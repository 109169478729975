import { createSlice } from '@reduxjs/toolkit'
import { getNewsCategoriesSkip } from 'services/newsService';

const selectedNewsCategorySlice = createSlice({
    name: 'selectedNewsCategory',
    initialState: {},
    reducers: {
        setSelectedNewsCategory: (state, action) => {
            let newsCategory;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                newsCategory = action.payload[0]
            } else {
                newsCategory = {}
            }            

            return newsCategory
        },
    },
})

export const selectNewsCategoryForDetails = (id) => async (dispatch) => {
    try {
        const selectedNewsCategory = await getNewsCategoriesSkip(0, id)
        dispatch(setSelectedNewsCategory(selectedNewsCategory))
    } catch (error) {
        console.error('Erro ao carregar missão:', error)
    }
}

export const cleanNewsCategoryForDetails = () => async (dispatch) => {
    dispatch(setSelectedNewsCategory([]))
}

export const { setSelectedNewsCategory } = selectedNewsCategorySlice.actions
export default selectedNewsCategorySlice.reducer