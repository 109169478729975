import React, { useEffect, useState } from 'react'
import Button from 'components/form/Button'
import Modal from 'components/Modal'
import style from '../../../../styles/Proof.module.css'
import styles from '../../../../styles/UserTabs.module.css'
import { uuidMask } from 'helpers/masks'
import { getInfractionOptions, getUserInfractionPoints, getUserInfractionPointsByUserId, getUserStep, getUserStepByUserId, saveUserInfractionByUserId } from 'services/infractionService'
import { toast } from 'react-toastify'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { getSwearWordsFilter, saveSwearWordsFilter } from 'services/blockFilterService'

export default function SwearWordsEdit() {
    const verifySwearWords = useUserPermission(SMIPermissionsCode.MOD_SWEAR_WORDS_MANAGE);
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [textAreaValue, setTextAreaValue] = useState("")
    const [originalTextAreaValue, setOriginalTextAreaValue] = useState("")
    const [hasChanged, setHasChanged] = useState(false)

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        handleSwearWordsFilter()
        setIsModalOpen(false)
    }

    useEffect(() => {
        handleSwearWordsFilter()
    }, [])

    const handleSwearWordsFilter = async () => {
        let regexFilter = await getSwearWordsFilter()
        setTextAreaValue(regexFilter);
        setOriginalTextAreaValue(regexFilter);
    }

    const handleOnChangeTextArea = async (event) => {
        setTextAreaValue(event.target.value)
    }


    const handleSaveSwearWordsFilter = async () => {
        try {
            await saveSwearWordsFilter(textAreaValue)
            handleCloseModal()
            toast.success('Filtro Atualizado com Sucesso!')
        }
        catch {
            toast.error('Erro ao Atualizar Filtro!')
        }
    }

    const stripFormatting = (text) => {
        return text
          .replace(/\s+/g, ' ') 
          .trim();                 
      };

    useEffect(() => {
        if(stripFormatting(textAreaValue) == stripFormatting(originalTextAreaValue)){
            setHasChanged(true)
        }
        else{
            setHasChanged(false)
            console.log(textAreaValue)
            console.log(originalTextAreaValue)
        }
    }, [textAreaValue])

    return (
        <>
            {verifySwearWords && <div>
                <Modal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    header={<h3>Atualizar Filtro de palavras proibidas</h3>}
                    footer={
                        <div className={style.userInfractionModalControler}>
                            <Button disabled={hasChanged} text="Salvar" onClick={handleSaveSwearWordsFilter} color="cyan" />
                            <Button text="Fechar" onClick={handleCloseModal} color="cyan" />
                        </div>}
                >
                    <div className={style.infractionModalContainer}>
                    {<textarea rows="4" value={textAreaValue} onChange={handleOnChangeTextArea} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-[57vh]"></textarea>}
                    </div>
                </Modal>
                <button onClick={handleOpenModal} className='block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-cyan-200 w-full text-left'>Palavras proibidas</button>
            </div>}
        </>
    )
}
