import { tournamentsMapping } from "domains/Tournaments/mapping";
import Tournament from "domains/Tournaments/Tournament";
import api, { apiNormalize } from "./api";
import TournamentDetail from "domains/Tournaments/TournamentDetail";
import handleRequestError from "helpers/apiErrorHandler";

export async function getTournaments(params, skip) {
    const response = await api.get("/tournaments", { params: { ...params, skip } });
    const tournaments = apiNormalize.responseMapping(response.data, tournamentsMapping)
    const normalizedTournaments = tournaments.map(tournament => new Tournament(tournament).get());
    return normalizedTournaments
}

export async function getSelectedTournamentDetails(params, skip) {
    const response = await api.get("/tournaments", { params: { ...params, skip } });
    const tournaments = apiNormalize.responseMapping(response.data, tournamentsMapping)
    const normalizedTournaments = tournaments.map(tournament => new TournamentDetail(tournament).get());
    return normalizedTournaments[0]
}

export async function saveTournament(tournamentId, changes){
        try{
            const response = await api.post(`/tournaments/${tournamentId}/saveTournament`, {changes})
            return response.data
        }
        catch(error){
            handleRequestError(error)
            throw error;
        }
}

export const deleteTournament = async (tournamentId) => {
    try {
        const response = await api.post(`/tournaments/${tournamentId}/deleteTournament`)
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const createTournament = async (changes) => {
    try {
        const formData = new FormData();
        
        formData.append('changes', JSON.stringify(changes));

        const response = await api.post(`/tournaments/createTournament`, {changes});
        return response.data;
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}