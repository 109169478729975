// useTransactions.js
import { useState, useEffect } from 'react'
import { BiCoin } from 'react-icons/bi'
import { PiPokerChipBold } from 'react-icons/pi'
import { useSelector, useDispatch } from 'react-redux'
import api from 'services/api'
import { fetchTransactions } from 'store/modules'
import { fetchTransactionById } from 'store/modules/transactions/transactionActions'
import { setTransactions } from 'store/modules/transactions/transactionSlice'

export default function useTransactions (sender, receiver, space_id, space_instance_id, item_id, dateFrom, dateTo, transactionType, desc) {
    const [isLoading, setLoading] = useState(false)
    const [isLoadingDetails, setLoadingDetails] = useState(false)
    const [filter, setFilter] = useState({})

    const transactions = useSelector((state) => {
        return Array.isArray(state.transactions.data) ? state.transactions.data : []
    })

    useEffect(() => {
        setLoading(false)
    }, [transactions]);

    const dispatch = useDispatch()

    const skip = useSelector((state) => {
        return (state.transactions.currentSkip) ? state.transactions.currentSkip : 0
    })

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (sender || receiver || space_id || space_instance_id || item_id || dateFrom || dateTo || transactionType || desc) {
            setLoading(true)
            dispatch(fetchTransactions({sender, receiver, space_id, space_instance_id, item_id, dateFrom, dateTo, transactionType, desc}, 0))
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.sender) query.append('sender', filter.form.sender);
        if (filter.form.receiver) query.append('receiver', filter.form.receiver);
        if (filter.form.space_id) query.append('space_id', filter.form.space_id);
        if (filter.form.space_instance_id) query.append('space_instance_id', filter.form.space_instance_id);
        if (filter.form.item_id) query.append('item_id', filter.form.item_id);
        if (filter.form.dateFrom) query.append('dateFrom', filter.form.dateFrom);
        if (filter.form.dateTo) query.append('dateTo', filter.form.dateTo);
        if (filter.form.transactionType) query.append('transactionType', filter.form.transactionType);
        if (filter.form.desc) query.append('desc', filter.form.desc);
        window.history.pushState({}, '', `/revenue/transactions?${query.toString()}`);
        dispatch(fetchTransactions(filter.form, 0))
    }

    const searchTransactionById = async (item) => {
        setLoadingDetails(true)
        const transaction = await fetchTransactionById(item.id)
        setLoadingDetails(false)
        return transaction
    }

    const cancel = () => {
        setLoading(false)
    }

    const handleSetFilter = ({ filter, form, isValid }) => {
        console.log('AQUI')
        setFilter({ filter, form, isValid })
    }

    const handleFormChange = ({form, isValid}) => {
        console.log(isValid)
        handleSetFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        dispatch(fetchTransactions(filter.form, skip+200));
    })

    const hasMore = useSelector((state) => {
        return Boolean(state.transactions.hasMore)
    })

    const filters = useSelector((state) => {
        return Object(state.transactions.filters) ? state.transactions.filters : {}
    })

    return {
        isLoading,
        isLoadingDetails,
        filter,
        transactions,
        searchTransactionById,
        hasMore,
        skip,
        search,
        cancel,
        handleFormChange,
        handleLoadMore,
        filters
    }
}
