class Widget {
    constructor({ widgetId, itemId, creatorAvatarId, creatorAvatarFirstName, creatorAvatarLastName, creatorAvatarNameInstance, creatorAvatarIcon, modelId, modelName, modelIcon, loves, imageFileName, dataFileName, published }) {
        this.widgetId = widgetId
        this.itemId = itemId
        this.creatorAvatarId = creatorAvatarId
        this.creatorAvatarFirstName = creatorAvatarFirstName
        this.creatorAvatarLastName = creatorAvatarLastName
        this.creatorAvatarNameInstance = creatorAvatarNameInstance
        this.creatorAvatarIcon = creatorAvatarIcon
        this.modelId = modelId
        this.modelName = modelName
        this.modelIcon = modelIcon
        this.loves = loves
        this.imageFileName = imageFileName
        this.dataFileName = dataFileName
        this.published = published
    }

    get() {
        return {
            widgetId: this.widgetId,
            smallWidgetId: this.smallID(this.widgetId),
            itemId: this.itemId,
            smallItemId: this.smallID(this.itemId),
            modelId: this.modelId,
            modelName: this.modelName,
            modelIcon: this.modelIcon,
            avatarId: this.creatorAvatarId,
            creatorAvatarName: this.avatarFullName(this.creatorAvatarNameInstance, this.creatorAvatarFirstName, this.creatorAvatarLastName),
            avatarIcon: this.creatorAvatarIcon,
            loves: this.loves,
            published: this.published,
            imgFileName: this.imageFileName,
        }
    }

    avatarFullName(avatarNameInstance, avatarFirstName, avatarLastName) {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (avatarNameInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarNameInstance);
        else if (avatarFirstName === null || avatarLastName === null) return "Avatar Apagado"
        else return avatarFirstName + " " + avatarLastName;
    }

    smallID(id) {
        return id?.slice(0, 7)
    }
}

export default Widget