class Infraction {
    constructor({ id, horario, userFirstName, userLastName, offenceName, offencePoints, chainName, punishmentName, moderador, userId }) {
        this.id = id
        this.horario = horario
        this.userFirstName = userFirstName
        this.userLastName = userLastName
        this.offenceName = offenceName
        this.offencePoints = offencePoints
        this.chainName = chainName
        this.punishmentName = punishmentName
        this.moderador = moderador
        this.userId = userId
    }

    get() {
        return {
            id: this.id,
            userId: this.userId,
            horario: this.horario,
            user: this.fullName(this.userFirstName, this.userLastName),
            ofensa: `${this.offenceName} (${this.offencePoints} pontos)`,
            punicao: `${this.chainName} > ${this.punishmentName}`,
            moderador: this.moderador
        }
    }

    smallID() {
        return this.id?.slice(0, 7)
    }

    fullName(userFirstName, userLastName) {
        if (userFirstName === null || userLastName === null) return "Usuário Apagado"
        else return `${userFirstName} ${userLastName}`
    }
}

export default Infraction
