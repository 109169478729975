import moment from "moment"

const infractionMapping = item => ({
    id: item.id,
    userId: item.user?.id ?? null,
    horario: moment(item.timestamp).format("DD/MM HH:mm") ?? null,
    userFirstName: item.user.firstName ?? null,
    userLastName: item.user.lastName ?? null,
    offenceName: item.typeName ?? null,
    offencePoints: item.points ?? null,
    chainName: item.chainName ?? null,
    punishmentName: item.stepName ?? null,
    moderador: getModerador(item) ?? null,
})

const getId = (id) => {
    return typeof id === 'object' ? id.oid : id
}

const getModerador = (item) => {
    return (item.modUser?.firstName + " " + item.modUser?.lastName) || "Usuário Apagado"
}

const allInfractionsMapping = item => ({
    userInfractionId: item.id ?? null,
    date: item.timestamp ?? null,
    userId: item.user.id ?? null,
    userFirstName: item.user.firstName ?? null,
    userLastName: item.user.lastName ?? null,
    offenceName: item.typeName ?? null,
    offencePoints: item.points ?? null,
    chainName: item.chainName ?? null,
    punishmentName: item.stepName ?? null,
    modId: item.modUser.id ?? null,
    modFirstName: item.modUser.firstName ?? null,
    modLastName: item.modUser.lastName ?? null,
    active: item.active ? 'Sim' : 'Não',
    abuseReportId: item.abuseReport ? item.abuseReport.id ?? null : null,
    proofList: item.abuseReport ? item.abuseReport.proofList ?? null : null,
    reportedModNotes: item.reportedModNotes ?? '',
    reportedUserId: item.reportedUserId ?? null,
})

export { infractionMapping, allInfractionsMapping }
