import Button from "components/form/Button";
import Modal from "components/Modal";
import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode";
import useUserPermission from "domains/Permissions/useUserPermission";
import { useState } from "react";
import { toast } from "react-toastify";
import { createBroadcastNotification } from "services/broadcastNotification";
import style from 'styles/Proof.module.css'
import styles from 'styles/UserTabs.module.css'


export default function BroadcastNotification() {
    const verifyBroadcastNot = useUserPermission(SMIPermissionsCode.BROADCAST_NOTIFICATION_MANAGE);

    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setMsgInputValue("")
        setInputValue("info")
        setIsModalOpen(false)
    }
    const [inputValue, setInputValue] = useState('info')
    const [msgInputValue, setMsgInputValue] = useState("")

    const handleOnChangeInput = async (event) => {
        setInputValue(event.target.value)
    }

    const handleOnChangeMsgInput = async (event) => {
        setMsgInputValue(event.target.value)
    }

    const handleSaveUserInfraction = async () => {
        try {
            await createBroadcastNotification(msgInputValue, inputValue);

            handleCloseModal()
            toast.success('Notificação Emitida com Sucesso!')
        }
        catch {
            toast.error('Erro ao Emitir Notificação!')
        }
    }

    return (
        <>
            {verifyBroadcastNot && <div>
                <Modal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    header={<h3>Broadcast Notification</h3>}
                    footer={
                        <div className={style.userInfractionModalControler}>
                            <Button disabled={msgInputValue.trim() == ''} text="Salvar" onClick={handleSaveUserInfraction} color="cyan" />
                        </div>}
                >
                    <div className={style.infractionModalContainer}>
                        <div className={style.infractionModalContainerUser}>
                            <input value={msgInputValue} className={styles.modalInput} onChange={handleOnChangeMsgInput} placeholder='Mensagem' />
                        </div>
                        <select value={inputValue} className={styles.select} onChange={handleOnChangeInput}>
                        <option key={'info'} value={'info'}>Informação</option>
                        <option key={'error'} value={'error'}>Erro</option>
                        <option key={'warn'} value={'warn'}>Warning</option>
                        </select>
                    </div>
                </Modal>
                <button onClick={handleOpenModal} className='block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-cyan-200 w-full text-left'>Notificação Broadcast</button>
            </div>}
        </>
    )
}
