import News from "Pages/News";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchNewsFilters } from "store/modules/news/newsSlice";

export function NewsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const newsId = searchParams.get("newsId");
    const title = searchParams.get("title");
    const authorId = searchParams.get("authorId");
    const shortText = searchParams.get("shortText");
    const fullText = searchParams.get("fullText");
    const categoryId = searchParams.get("categoryId");
    const dateFrom = searchParams.get("dateFrom");
    const dateTo = searchParams.get("dateTo");

    const dispatch = useDispatch()

    if (newsId || title || authorId || shortText || fullText || categoryId || dateFrom || dateTo) {
        fetchNewsFilters(dispatch, {newsId, title, newsId, shortText, fullText, categoryId, dateFrom, dateTo })
        return <News newsId={newsId ?? null} title={title ?? null} authorId={authorId ?? null} shortText={shortText ?? null} fullText={fullText ?? null} categoryId={categoryId ?? null} dateFrom={dateFrom ?? null} dateTo={dateTo ?? null}/>;
    }
    else{
        return <News/>;
    }

}