import handleRequestError from "helpers/apiErrorHandler";
import api, { apiNormalize } from "./api";
import { avatarNoNamesMapping } from "domains/Moderation/AvatarNoName/mapping";
import AvatarNoName from "domains/Moderation/AvatarNoName/AvatarNoName";
import BlackListItem from "domains/Moderation/Blacklist/BlackListItem";
import { blackListMapping } from "domains/Moderation/Blacklist/mapping";
import { emailProviderBlacklistMapping } from "domains/Moderation/EmailProviderBlacklist/mapping";
import EmailProviderBlacklistItem from "domains/Moderation/EmailProviderBlacklist/EmailProviderBlacklistItem";

export const getSwearWordsFilter = async () => {
    try {
        const response = await api.get('/blockFilter/getSwearWordsFilter')
        return(response.data)
    } catch (error) {
        handleRequestError(error)
    }
}

export const saveSwearWordsFilter = async (textValue) => {
    try {
        const response = await api.post(`/blockFilter/saveSwearWordsFilter`, { textValue })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const getAvatarNoNames = async (params, skip) => {
    const response = await api.get("/blockFilter/getAvatarNoNames", {params:{...params,skip}});
    const avatarNoNames = apiNormalize.responseMapping(response.data, avatarNoNamesMapping)
    const normalizedAvatarNoNames = avatarNoNames.map(avatarNoName => new AvatarNoName(avatarNoName).get());
    return normalizedAvatarNoNames
}

export const getBlackList = async (params, skip) => {
    const response = await api.get("/blockFilter/getBlackList", {params:{...params,skip}});
    const blackList = apiNormalize.responseMapping(response.data, blackListMapping)
    const normalizedBlackList = blackList.map(blackListItem => new BlackListItem(blackListItem).get());
    return normalizedBlackList
}

export const getEmailProviderBlacklist = async (params, skip) => {
    const response = await api.get("/blockFilter/getEmailProviderBlacklist", {params:{...params,skip}});
    const blackList = apiNormalize.responseMapping(response.data, emailProviderBlacklistMapping)
    const normalizedBlackList = blackList.map(blackListItem => new EmailProviderBlacklistItem(blackListItem).get());
    return normalizedBlackList
}

export const deleteAvatarNoName = async (params) => {
    try{
        const response = await api.post(`/blockFilter/deleteAvatarNoName`, {params})
        return response.data
    }
    catch(error){
        console.log(error)
        handleRequestError(error)
    }
}

export const createAvatarNoName = async (params) => {
    try{
        const response = await api.post(`/blockFilter/createAvatarNoName`, {name: params});
        return response.data
    }
    catch(error){
        console.log(error)
        handleRequestError(error)
    }
}

export const deleteBlackList = async (params) => {
    try{
        const response = await api.post(`/blockFilter/deleteBlackList`, {params})
        return response.data
    }
    catch(error){
        console.log(error)
        handleRequestError(error)
    }
}

export const createBlackList = async (params) => {
    try{
        const response = await api.post(`/blockFilter/createBlackList`, {address: params});
        return response.data
    }
    catch(error){
        console.log(error)
        handleRequestError(error)
    }
}

export const deleteEmailProviderBlacklist = async (params) => {
    try{
        const response = await api.post(`/blockFilter/deleteEmailProviderBlacklist`, {params})
        return response.data
    }
    catch(error){
        console.log(error)
        handleRequestError(error)
    }
}

export const createEmailProviderBlacklist = async (params) => {
    try{
        const response = await api.post(`/blockFilter/createEmailProviderBlacklist`, {provider: params});
        return response.data
    }
    catch(error){
        console.log(error)
        handleRequestError(error)
    }
}