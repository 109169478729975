import moment from "moment";
import styles from '../../styles/Search.module.css'
import styleTabs from '../../styles/UserTabs.module.css'
import style from '../../styles/News.module.css'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "store/modules/news/newsSlice";
import NewsFilter from "./NewsFilter";
import Button from "components/form/Button";
import BasicTable from "components/BasicTable";
import Details from "./Partials/Details";
import { selectNewsForDetails } from "store/modules/news/selectedNewsSlice";
import Tabs from "components/Tabs";
import Modal from "components/Modal";
import Select from 'react-select'
import { createNews, getNewsCategories } from "services/newsService";
import { fetchNewsCatList } from "store/modules/news/newsCatListSlice";
import { toast } from "react-toastify";
import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode";
import useUserPermission from "domains/Permissions/useUserPermission";

export default function News({ newsId, title, authorId, shortText, fullText, categoryId, dateFrom, dateTo }) {

    const verifyNews = useUserPermission(SMIPermissionsCode.NEWS_MANAGE);

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto',
        }),
        control: (provided, state) => ({
            ...provided,
            width: '100%',
            paddingTop: '0px',
            paddingBottom: '0.1rem',
            marginTop: '3px',
            borderColor: state.isFocused ? '#155e75' : '#d1d5db',
            '&:hover': {
                borderColor: '#d1d5db'
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#6b7280',
        })
    };
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [newsCategories, setNewsCategories] = useState([])
    const [selectedNewsCategories, setSelectedNewsCategories] = useState([])
    const [titleValueInput, setTitleValueInput] = useState({ 'pt-BR': '', 'en-US': '', 'es-ES': '' })
    const [imgValueInput, setImgValueInput] = useState({ 'pt-BR': '', 'en-US': '', 'es-ES': '' })
    const [shortTextInput, setShortTextInput] = useState({ 'pt-BR': '', 'en-US': '', 'es-ES': '' })
    const [fullTextInput, setFullTextInput] = useState({ 'pt-BR': '', 'en-US': '', 'es-ES': '' })
    const [isFull, setIsFull] = useState(false);

    const iconObject = { key: 'authorIcon' }

    const [newsHeaders, setNewsHeaders] = useState([
        { title: 'Data', key: 'date', appear: true },
        { title: 'ID da Notícia', key: 'smallNewsId', copy: 'newsId', detailable: 'true', appear: true },
        { title: 'Título', key: 'title', appear: true },
        { title: 'Nome do Autor', key: 'authorName', copy: 'authorId', icon: () => iconObject, appear: true },
    ]);

    const [newsMomentHeaders, setNewsMomentHeaders] = useState([...newsHeaders]);

    const handleAppearHeader = (item) => {
        setNewsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setNewsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const news = useSelector((state) => {
        return Array.isArray(state.news.data) ? state.news.data : []
    })

    const selectedNews = useSelector((state) => {
        return state.selectedNews
    })

    const skip = useSelector((state) => {
        return (state.news.currentSkip) ? state.news.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.news.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.news.filters) ? state.news.filters : {}
    })

    const newsCatList = useSelector((state) => {
        return Array.isArray(state.newsCatList.data) ? state.newsCatList.data : []
    })

    useEffect(() => {
        setLoading(false)
    }, [news]);

    const dispatch = useDispatch()

    useEffect(() => {
        if (newsCatList.length == 0) {
            fetchNewsCatList(dispatch)
        }
        handleParams()
    }, [])

    const handleParams = async () => {
        if (newsId || title || authorId || shortText || fullText || categoryId || dateFrom || dateTo) {
            setLoading(true)
            fetchNews(dispatch, { newsId, title, authorId, shortText, fullText, categoryId, dateFrom, dateTo }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.newsId) query.append('newsId', filter.form.newsId);
        if (filter.form.title) query.append('title', filter.form.title);
        if (filter.form.authorId) query.append('authorId', filter.form.authorId);
        if (filter.form.shortText) query.append('shortText', filter.form.shortText);
        if (filter.form.fullText) query.append('fullText', filter.form.fullText);
        if (filter.form.categoryId) query.append('categoryId', filter.form.categoryId);
        if (filter.form.dateFrom) query.append('dateFrom', filter.form.dateFrom);
        if (filter.form.dateTo) query.append('dateTo', filter.form.dateTo);
        window.history.pushState({}, '', `/news?${query.toString()}`);
        fetchNews(dispatch, { ...filter.form, dateTo: moment(filter.form.dateTo).utc().format(), dateFrom: moment(filter.form.dateFrom).utc().format() }, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchNews(dispatch, filter.form, skip + 200);
    })

    const handleNewsDetails = (item) => {
        dispatch(selectNewsForDetails({ newsId: item.newsId }))
    }

    const handleOpenCreateModal = async () => {
        setIsCreateModalOpen(true)
        console.log(newsCatList)
    }

    const handleCloseCreateModal = () => {
        setSelectedNewsCategories([])
        setTitleValueInput({ 'pt-BR': '', 'en-US': '', 'es-ES': '' })
        setImgValueInput({ 'pt-BR': '', 'en-US': '', 'es-ES': '' })
        setShortTextInput({ 'pt-BR': '', 'en-US': '', 'es-ES': '' })
        setFullTextInput({ 'pt-BR': '', 'en-US': '', 'es-ES': '' })
        setIsFull(false);
        setIsCreateModalOpen(false)
    }

    const handleTitle = (e, lan) => {
        let tempTitle = { ...titleValueInput, [lan]: e.target.value }
        setTitleValueInput(tempTitle)
    }

    const handleImg = (e, lan) => {
        let tempImg = { ...imgValueInput, [lan]: e.target.files[0] }
        setImgValueInput(tempImg)
        console.log(imgValueInput)
    }

    const handleShortText = (e, lan) => {
        let tempShortText = { ...shortTextInput, [lan]: e.target.value }
        setShortTextInput(tempShortText)
    }

    const handleFullText = (e, lan) => {
        let tempFullText = { ...fullTextInput, [lan]: e.target.value }
        setFullTextInput(tempFullText)
    }

    const areAllFieldsFilled = (inputObject) => {
        return Object.values(inputObject).every(value => value !== '');
    };

    useEffect(() => {
        if (selectedNewsCategories.length != 0 && areAllFieldsFilled(fullTextInput) && areAllFieldsFilled(shortTextInput) && areAllFieldsFilled(imgValueInput) && areAllFieldsFilled(titleValueInput)) {
            setIsFull(true)
        }
        else {
            setIsFull(false)
        }

    }, [fullTextInput, shortTextInput, imgValueInput, titleValueInput, selectedNewsCategories])

    const handleCreateNews = async () => {
        try{
            await createNews({fullText: fullTextInput, shortText: shortTextInput, title: titleValueInput, selectedNewsCategories}, imgValueInput)
            handleCloseCreateModal();
            await fetchNews(dispatch, {}, 0);
            toast.success("Dados Salvos com Sucesso!")
        }
        catch{
            toast.error("Erro ao Salvar Dados!")
        }
    }

    const tabs = []

    tabs.push(
        {
            id: 1,
            tabTitle: 'Propriedades - PT',
            content: (
                <>
                    <div className={style.propContainer}>
                        <div className={style.propContainerContent}>
                            <div className={style.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`Título`}</span>
                                <input className={style.modalInput} type="text" placeholder={`Título`} value={titleValueInput['pt-BR']} onChange={(e) => handleTitle(e, 'pt-BR')} />
                            </div>

                            <div className={style.generalContainerInput} style={{overflowY: 'hidden'}}>
                                <span className={styleTabs.modalImgLabel}>{`Imagem`}</span>
                                <input className={style.inputFileHide} id="customFileInput" type='file' accept=".png" onChange={(e) => handleImg(e, 'pt-BR')} />

                                <div className={style.customFileInputContainer}>
                                    <label htmlFor="customFileInput" className={style.customFileButton}>
                                        Escolher arquivo
                                    </label>
                                    {imgValueInput['pt-BR'] ?
                                        (<p className={style.fileName}>{`${imgValueInput['pt-BR'].name}`}</p>)
                                        :
                                        (<p className={style.fileName}>{`Nenhum Arquivo selecionado...`}</p>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={style.propContainerContent}>
                            <div className={style.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`Texto Curto`}</span>
                                <textarea className={`${style.modalInput} ${style.modalInputArea}`} type="text" placeholder={`Texto Curto`} value={shortTextInput['pt-BR']} onChange={(e) => handleShortText(e, 'pt-BR')} />
                            </div>
                            <div className={style.generalContainerInput}>
                                <span className={styleTabs.modalImgLabel}>{`Texto Completo`}</span>
                                <textarea className={`${style.modalInput} ${style.modalInputArea}`} type="text" placeholder={`Texto Completo`} value={fullTextInput['pt-BR']} onChange={(e) => handleFullText(e, 'pt-BR')} />
                            </div>
                        </div>
                    </div>
                </>
            )
        },

        {
            id: 2,
            tabTitle: 'Propriedades - EN',
            content: (
                <>
                    <div className={style.propContainer}>
                        <div className={style.propContainerContent}>
                            <div className={style.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`Título`}</span>
                                <input className={style.modalInput} type="text" placeholder={`Título`} value={titleValueInput['en-US']} onChange={(e) => handleTitle(e, 'en-US')} />
                            </div>
                            <div className={style.generalContainerInput} style={{overflowY: 'hidden'}}>
                                <span className={styleTabs.modalImgLabel}>{`Imagem`}</span>
                                <input className={style.inputFileHide} id="customFileInput" type='file' accept=".png" onChange={(e) => handleImg(e, 'en-US')} />

                                <div className={style.customFileInputContainer}>
                                    <label htmlFor="customFileInput" className={style.customFileButton}>
                                        Escolher arquivo
                                    </label>
                                    {imgValueInput['en-US'] ?
                                        (<p className={style.fileName}>{`${imgValueInput['en-US'].name}`}</p>)
                                        :
                                        (<p className={style.fileName}>{`Nenhum Arquivo selecionado...`}</p>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={style.propContainerContent}>
                            <div className={style.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`Texto Curto`}</span>
                                <textarea className={`${style.modalInput} ${style.modalInputArea}`} type="text" placeholder={`Texto Curto`} value={shortTextInput['en-US']} onChange={(e) => handleShortText(e, 'en-US')} />
                            </div>
                            <div className={style.generalContainerInput}>
                                <span className={styleTabs.modalImgLabel}>{`Texto Completo`}</span>
                                <textarea className={`${style.modalInput} ${style.modalInputArea}`} type="text" placeholder={`Texto Completo`} value={fullTextInput['en-US']} onChange={(e) => handleFullText(e, 'en-US')} />
                            </div>
                        </div>
                    </div>
                </>
            )
        },

        {
            id: 3,
            tabTitle: 'Propriedades - ES',
            content: (
                <>
                    <div className={style.propContainer}>
                        <div className={style.propContainerContent}>
                            <div className={style.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`Título`}</span>
                                <input className={style.modalInput} type="text" placeholder={`Título`} value={titleValueInput['es-ES']} onChange={(e) => handleTitle(e, 'es-ES')} />
                            </div>
                            <div className={style.generalContainerInput} style={{overflowY: 'hidden'}}>
                                <span className={styleTabs.modalImgLabel}>{`Imagem`}</span>
                                <input className={style.inputFileHide} id="customFileInput" type='file' accept=".png" onChange={(e) => handleImg(e, 'es-ES')} />

                                <div className={style.customFileInputContainer}>
                                    <label htmlFor="customFileInput" className={style.customFileButton}>
                                        Escolher arquivo
                                    </label>
                                    {imgValueInput['es-ES'] ?
                                        (<p className={style.fileName}>{`${imgValueInput['es-ES'].name}`}</p>)
                                        :
                                        (<p className={style.fileName}>{`Nenhum Arquivo selecionado...`}</p>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={style.propContainerContent}>
                            <div className={style.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`Texto Curto`}</span>
                                <textarea className={`${style.modalInput} ${style.modalInputArea}`} type="text" placeholder={`Texto Curto`} value={shortTextInput['es-ES']} onChange={(e) => handleShortText(e, 'es-ES')} />
                            </div>
                            <div className={style.generalContainerInput}>
                                <span className={styleTabs.modalImgLabel}>{`Texto Completo`}</span>
                                <textarea className={`${style.modalInput} ${style.modalInputArea}`} type="text" placeholder={`Texto Completo`} value={fullTextInput['es-ES']} onChange={(e) => handleFullText(e, 'es-ES')} />
                            </div>
                        </div>
                    </div>
                </>
            )
        },
    )

    return (
        <div className='grid grid-cols-12 gap-4'>

            {verifyNews && <div className='col-span-6'>
                <BasicTable
                    onAppearHeader={handleAppearHeader}
                    headerControl={true}
                    originalHeaders={newsHeaders}
                    headers={newsMomentHeaders}
                    slotHeader={<NewsFilter onFilterChange={handleSetFilter} defaultValues={filters} />}
                    title='Notícias'
                    onDetailRow={handleNewsDetails}
                    isLoading={isLoading && news.length === 0}
                    slotActions={
                        <>
                            <Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />
                            <Button text="Criar Notícia" onClick={handleOpenCreateModal} color="cyan" />
                        </>
                    }
                    items={news}
                    slotFooter={
                        <div className={styles.slotFooter}>
                            <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                            {(news.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : news.length} resultados</span>}
                        </div>
                    }
                />
            </div>}
            <div className='col-span-6'>

                {selectedNews.newsId && <Details item={selectedNews} />}

            </div>
            <Modal
                header={``}
                isOpen={isCreateModalOpen}
                onClose={handleCloseCreateModal}
                footer={<div className={style.modalFooter}><Button disabled={!isFull} text="Salvar" onClick={handleCreateNews} color="cyan" /></div>}
            >
                <div className={style.modalCreateNewsContainer}>
                    <div className={style.generalContainer}>
                        <div className='flex justify-between px-2 py-2 border-b font-medium bg-gradient-to-b from-gray-300'>
                            {"Dados Gerais"}
                        </div>
                        <div className={style.generalContainerContent}>
                            <div className={style.generalContainerSelect}>
                                <span className={styleTabs.modalLabel}>{`Categorias`}</span>
                                <Select
                                    classNamePrefix="select"
                                    onChange={(item) => setSelectedNewsCategories(item)}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="categories"
                                    styles={customStyles}
                                    options={newsCatList}
                                    isMulti
                                    placeholder={"Selecione as categorias"}
                                />
                            </div>
                        </div>
                    </div>


                    <div>
                        <div className='flex justify-between px-2 py-2 border-b font-medium bg-gradient-to-b from-gray-300'>
                            {"Dados por Idioma"}
                        </div>
                        <Tabs
                            tabs={tabs}
                        />
                    </div>

                </div>
            </Modal>
        </div>
    )
}