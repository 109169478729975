
import api, { apiNormalize } from './api'
import handleRequestError from 'helpers/apiErrorHandler';
import { storeLogsMapping } from 'domains/Moderation/StoreLog/mapping';
import StoreLog from 'domains/Moderation/StoreLog/StoreLog';

export async function getStoreLogs(params, skip) {
    try {
        const response = await api.get("/storeLogs", { params: { ...params, skip } });
        const storeLogs = apiNormalize.responseMapping(response.data, storeLogsMapping)
        const normalizedStoreLogs = storeLogs.map(storeLog => new StoreLog(storeLog).get());
        return normalizedStoreLogs
    }

    catch (error) {
        console.log(error)
        handleRequestError(error)
    }
}