import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import FormSearch from './Partials/FormSearch'
import Button from 'components/form/Button'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../../../styles/Search.module.css'
import { fetchChatLogs } from 'store/modules/moderation/chatLogs/chatLogsSlice'
import ChatLogsFilter from './ChatLogsFilter'
import moment from 'moment'

export default function ChatLogs({ avatarId, spaceId, instanceId, dateFrom, dateTo, messageText, fromAbuseReport }) {
    const iconObject = { key: 'avatarIcon' }

    const [chatLogsHeaders, setChatLogsHeaders] = useState([
        { title: 'ID', key: 'smallId', copy: 'messageId', appear: true },
        { title: 'Data', key: 'date', appear: true },
        { title: 'Mensagem', key: 'message', appear: true },
        { title: 'Nome do Avatar', key: 'avatarName', copy: 'avatarId', icon: () => iconObject, appear: true },
        { title: 'Nome do espaço', key: 'spaceName', copy: 'spaceId', appear: true },
        { title: 'ID da Instância', key: 'instanceId', appear: true },
    ])

    const [chatLogsMomentHeaders, setChatLogsMomentHeaders] = useState([...chatLogsHeaders]);

    const handleAppearHeader = (item) => {
        setChatLogsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setChatLogsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }



    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const chatLogs = useSelector((state) => {
        return Array.isArray(state.chatLogs.data) ? state.chatLogs.data : []
    })

    const skip = useSelector((state) => {
        return (state.chatLogs.currentSkip) ? state.chatLogs.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.chatLogs.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.chatLogs.filters) ? state.chatLogs.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [chatLogs]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (avatarId || spaceId || instanceId || dateFrom || dateTo || messageText || fromAbuseReport) {
            setLoading(true)
            fetchChatLogs(dispatch, { avatarId, spaceId, instanceId, dateFrom: moment(dateFrom).utc().format(), dateTo: moment(dateTo).utc().format(), messageText, fromAbuseReport }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.avatarId) query.append('avatarId', filter.form.avatarId);
        if (filter.form.spaceId) query.append('spaceId', filter.form.spaceId);
        if (filter.form.instanceId) query.append('instanceId', filter.form.instanceId);
        if (filter.form.dateFrom) query.append('dateFrom', filter.form.dateFrom);
        if (filter.form.dateTo) query.append('dateTo', filter.form.dateTo);
        if (filter.form.messageText) query.append('messageText', filter.form.messageText);
        window.history.pushState({}, '', `/moderation/chat-logs?${query.toString()}`);
        fetchChatLogs(dispatch, { ...filter.form, fromAbuseReport: false, dateTo: moment(filter.form.dateTo).utc().format(), dateFrom: moment(filter.form.dateFrom).utc().format() }, 0);

        console.log({ ...filter.form, dateTo: moment(filter.form.dateTo).utc().format(), dateFrom: moment(filter.form.dateFrom).utc().format() })
    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchChatLogs(dispatch, { ...filter.form, fromAbuseReport: false, dateTo: moment(filter.form.dateTo).utc().format(), dateFrom: moment(filter.form.dateFrom).utc().format() }, skip + 200);
    })

    console.log(hasMore)
    return (
        <>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                        <ChatLogsFilter onFilterChange={handleSetFilter} defaultValues={filters} />
                    </div>
                </div>
                <div className='col-span-12'>
                    <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={chatLogsHeaders}
                        headers={chatLogsMomentHeaders}
                        title='Buscar Mensagens'
                        isLoading={isLoading && chatLogs.length === 0}
                        slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                        items={chatLogs}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMore || isLoading || chatLogs.length == 0} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(chatLogs.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : chatLogs.length} resultados</span>}
                            </div>
                        }
                    />
                </div>
            </div>
        </>
    )
}
