import { getAvatarNoNames } from "services/blockFilterService";

const { createSlice } = require("@reduxjs/toolkit");

const avatarNoNamesSlice = createSlice({
    name: 'avatarNoNames',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            nameValue: ''
        }
    },
    reducers: {
        setAvatarNoNamesData: (state, action) => {
            return {...state, data: action.payload}
        },
        addAvatarNoNamesData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setAvatarNoNamesFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchAvatarNoNames(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const avatarNoNames = await getAvatarNoNames(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addAvatarNoNamesData(avatarNoNames))
        }
        else{
            dispatch(setAvatarNoNamesData(avatarNoNames));
        }
        
        if(avatarNoNames.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Proibições', error)
    }
}

export async function fetchAvatarNoNamesFilters(dispatch, params){
    dispatch(setAvatarNoNamesFilter(params));
}

export const { setAvatarNoNamesData, addAvatarNoNamesData, setCurrentSkip, setHasMore, setAvatarNoNamesFilter } = avatarNoNamesSlice.actions
export default avatarNoNamesSlice.reducer