const { Paths } = require("paths/Paths");

const widgetsMapping = (item) => ({
    widgetId: item._id ?? null,
    itemId: item.itemId ?? null,
    creatorAvatarId: item.creatorAvatarId ?? null,
    creatorAvatarFirstName: item.creatorAvatarFirstName ?? null,
    creatorAvatarLastName: item.creatorAvatarLastName ?? null,
    creatorAvatarNameInstance: item.creatorAvatarNameInstance ?? null,
    creatorAvatarIcon: (!item.creatorAvatarFirstName || !item.creatorAvatarLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" : `https://cdn.minimania.app/avatars/${item.creatorAvatarIcon}`,
    modelId: item.modelId ?? null,
    modelName: item.modelName ?? null,
    modelIcon: `${Paths.libraryPath}${item.modelIcon}`,
    loves: item.loves ?? 0,
    imageFileName: `${Paths.widgetImagesPath}${item.imageFileName}`,
    dataFileName: item.dataFileName ?? '',
    published: item.published ? "Sim" : 'Não',
})

export { widgetsMapping }