class EmailProviderBlacklistItem {
    constructor({provider, id}){
        this.provider = provider
        this.id = id
    }

    get(){
        return({
            provider: this.provider,
            id: this.id
        })
    }
}

export default EmailProviderBlacklistItem