import DefaultForm from 'components/form/DefaultForm'
import { useDispatch } from 'react-redux';
import { fetchEmailProviderBlacklistFilters } from 'store/modules/moderation/emailProviderBlacklist/emailProviderBlacklistSlice';

export default function FormSearch ({onFilterChange, defaultValues}) {

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
            newValue[name] = value;

        fetchEmailProviderBlacklistFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'provider',
            label: 'Provedor',
            value: defaultValues.provider,
            placeholder: 'Provedor',
            type: 'text',
            validate: [],
            gridClass: 'col-span-12'
        }
    ]
    

    return (
        <>
            <DefaultForm fields={ fields } onChange={handleSubmit} />
        </>
    )
}
