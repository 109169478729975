const tournamentsMapping = (item) => ({
    tournamentId: item._id ?? null,
    gameId: item.gameId ?? null,
    desc: item.desc ?? null,
    details: item.details ?? null,
    bountyGold: item.bountyGold ?? null,
    bountyTokens: item.bountyTokens ?? null,
    endTime: item.endTime ?? null,
    groupEntry: item.groupEntry ? 'Sim' : 'Não',
    tdist_value: item.tdist_value ?? null
})

export {tournamentsMapping}