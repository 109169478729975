import { getTournaments } from "services/tournamentsService";

const { createSlice } = require("@reduxjs/toolkit");

const tournamentsSlice = createSlice({
    name: 'tournaments',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            tournamentsId: '',
            gameId: '',
        }
    },
    reducers: {
        setTournamentsData: (state, action) => {
            return { ...state, data: action.payload }
        },
        addTournamentsData: (state, action) => {
            return { ...state, data: [...state.data, ...action.payload] }
        },
        setCurrentSkip: (state, action) => {
            return { ...state, currentSkip: action.payload }
        },
        setHasMore: (state, action) => {
            return { ...state, hasMore: action.payload }
        },
        setTournamentsFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchTournaments(dispatch, params, skip) {
    const LIMIT = 200;
    try {
        const tournaments = await getTournaments(params, skip);
        dispatch(setCurrentSkip(skip));

        if (skip > 0) {
            dispatch(addTournamentsData(tournaments))
        }
        else {
            dispatch(setTournamentsData(tournaments));
        }

        if (tournaments.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch (error) {
        console.error('Erro ao obter Torneios', error)
    }
}

export async function fetchTournamentsFilters(dispatch, params) {
    dispatch(setTournamentsFilter(params));
}

export const { setTournamentsData, addTournamentsData, setCurrentSkip, setHasMore, setTournamentsFilter } = tournamentsSlice.actions
export default tournamentsSlice.reducer